$(document).ready(function () {
    // password validation
    $(document).on('input', '#password', function () {
        var val = $(this).val();
        var meter = document.getElementById("meter");
        var no = 0;
        if (val != "") {

            if (val.length <= 7) no = 1;

            if (val.length > 7 && val.length < 9) no = 2;

            if (val.length > 7 && (val.match(/[a-z,A-Z]/) || val.match(/\d+/) || val.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/))) no = 2;

            if (val.length > 21) no = 3;

            if (val.length > 7 && ((val.match(/[a-z,A-Z]/) && val.match(/\d+/)) || (val.match(/\d+/) && val.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) || (val.match(/[a-z,A-Z]/) && val.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)))) no = 3;

            if (val.length > 21 && ((val.match(/[a-z,A-Z]/) && val.match(/\d+/)) || (val.match(/\d+/) && val.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) || (val.match(/[a-z,A-Z]/) && val.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)))) no = 4;

            if (val.length > 7 && val.match(/[a-z,A-Z]/) && val.match(/\d+/) && val.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) no = 4;

            if (val.length > 31) no = 4;

            if (no == 1) {
                $('#password_invalid').removeClass();
                $('#password_invalid').addClass('text-danger  font-weight-700');
                $('#password_invalid').html("Very Weak.");
                $('[type="submit"]').attr('disabled', true);
            }

            if (no == 2) {
                $('#password_invalid').removeClass();
                $('#password_invalid').addClass('text-danger  font-weight-700');
                $('#password_invalid').html("Weak.");
                $('[type="submit"]').attr('disabled', true);
            }
            if (no == 3) {
                $('#password_invalid').removeClass();
                $('#password_invalid').addClass('text-warning   font-weight-700');
                $('#password_invalid').html("Medium");
                $('[type="submit"]').attr('disabled', false);
            }

            if (no == 4) {
                $('#password_invalid').removeClass();
                $('#password_invalid').addClass('text-success   font-weight-700');
                $('#password_invalid').html("Strong");
                $('[type="submit"]').attr('disabled', false);
            }
        } else {
            meter.style.backgroundColor = "white";
            document.getElementById("pass_type").innerHTML = "";
        }
    });


    function updateTextView(_obj) {
        var num = getNumber(_obj.val());
        if (num == 0) {
            _obj.val('');
        } else {
            _obj.val(num.toLocaleString());
        }
    }

    function getNumber(_str) {
        var arr = _str.split('');
        var out = new Array();
        for (var cnt = 0; cnt < arr.length; cnt++) {
            if (isNaN(arr[cnt]) == false) {
                out.push(arr[cnt]);
            }
        }
        return Number(out.join(''));
    }

    $(document).on('input', '.number_format', function () {
        updateTextView($(this));
    });


    $(document).on('input', '.nric_validator', function () {
        let _token = $('meta[name="csrf-token"]').attr('content');
        let ic = $(this).val();
        let pass = $(this).data('pass');

        $.ajax({
            type: 'POST',
            url: '/nric_validation',
            data: {
                _token: _token,
                nric: ic,
            },
            success: function (result) {
                if (result) {
                    $('#nric_invalid' + pass).hide();
                    $('#nric_invalid' + pass).text('');
                    $('[type="submit"]').attr('disabled', false);
                } else {
                    $('#nric_invalid' + pass).show();
                    $('#nric_invalid' + pass).text('NRIC is Invalid.');
                    $('[type="submit"]').attr('disabled', true);
                }
            },
        });
    });

    $(document).on('click', '.socialite', function () {
        PopupCenter($(this).data('url'), $(this).data('title'), 850, 850);
    });

    if ($('.old_nric').length > 0) {
        $('.old_nric').mask('A0000000');
    }

    if ($('.apply_financing_amount').length > 0) {
        // $('.apply_financing_amount').maskMoney();
    }

    $(document).on('change', '.select_country', function () {
        let thiss = $(this);
        $.ajax({
            type: 'POST',
            url: '/get/states',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                name: $(this).val(),
            },
            success: function (result) {
                if (result) {
                    $('.state' + thiss.data('id')).html('<option value="" selected disabled>Please Select</option>');
                    result.forEach(element => {
                        let html = '<option value="' + element.name + '">' + element.name + '</option>';
                        $('.state' + thiss.data('id')).append(html);
                    });
                }
            },
        });
    });



    $(document).on('change', '.select_state', function () {
        let thiss = $(this);
        $.ajax({
            type: 'POST',
            url: '/get/cities',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                name: $(this).val(),
            },
            success: function (result) {
                if (result) {
                    $('.city' + thiss.data('id')).html('<option value="" selected disabled>Please Select</option>');
                    result.forEach(element => {
                        let html = '<option value="' + element.name + '">' + element.name + '</option>';
                        $('.city' + thiss.data('id')).append(html);
                    });
                }
            },
        });
    });

    $('.jquery-multi-select').multiSelect();

});

function PopupCenter(url, title, w, h) {
    // Fixes dual-screen position                         Most browsers      Firefox
    var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
    var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

    var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    var systemZoom = width / window.screen.availWidth;
    var left = (width - w) / 2 / systemZoom + dualScreenLeft
    var top = (height - h) / 2 / systemZoom + dualScreenTop
    var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w / systemZoom + ', height=' + h / systemZoom + ', top=' + top + ', left=' + left);

    // Puts focus on the newWindow
    if (window.focus) newWindow.focus();
    $('.subToggle').css('display', 'none');
}
