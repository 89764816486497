$(document).ready(function () {

    $('#CDSAccListTable').DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        ajax: {
            "url": "/settings/cds/ajax",
            "type": "POST",
            "data": function (d) {
                d._token = $('meta[name="csrf-token"]').attr('content')
            },
        },
        columns: [
            {
                data: 'DT_RowIndex',
                name: 'DT_RowIndex',
                orderable: false,
                searchable: false,
            },
            {
                data: 'name',
                name: 'name',
                className: "text-left",
                render: function (data, type, row) {
                    return (row.stock_firm != null) ? data + '<small> - '+row.stock_firm+' ('+row.account_no+')</small>': data;
                }
            },
            {
                data: "cds_id",
                orderable: false,
                className: "text-center",
                render: function (data) {
                    if (data != null) {
                        let view    = '<button data-id="'+data+'" class="btn btn-sm d-sm-inline-block btn-primary m-1 SettingCDSEdit" title="Edit"><i class="fas fa-list"></i></button>';
                        let destroy  = '<button data-id="'+data+'" class="btn btn-sm d-sm-inline-block btn-danger m-1 SettingCDSDelete" title="Delete"><i class="fas fa-trash-alt"></i></button>';
                        return view + destroy;
                    } else {
                        return '';
                    }
                },
            },
        ],
        order: [
            [1, 'desc']
        ],
        pageLength: 25,
        language: {
            paginate: {
                previous: "<i class='fas fa-angle-left'></i>",
                next: "<i class='fas fa-angle-right'></i>"
            }
        }
    });

    $(document).on('click', '.SettingCDSEdit', function () {
        let _token = $('meta[name="csrf-token"]').attr('content');
        let id = $(this).data('id');
        let url = $('#SettingCDSEditFrom').attr('action');
        $('#SettingCDSEditFrom').attr('action', url.replace('null', id));

        $.ajax({
            type: 'POST',
            url: '/settings/cds/ajax/detail',
            data: {
                _token: _token,
                id: id,
            },
            success: function (result) {
                if (result) {
                    $('#SettingCDSEditName').val(result.name);
                    $('#SettingCDSEditStockFirm').val(result.stock_firm);
                    $('#SettingCDSEditAccountNo').val(result.account_no);
                    $('#SettingCDSEditModal').modal('toggle');
                }
            },
        });
    });

    $(document).on('click', '.SettingCDSDelete', function () {
        let id = $(this).data('id');
        let url = $('#SettingCDSDeleteForm').attr('action');
        $('#SettingCDSDeleteForm').attr('action', url.replace('null', id));
        $('#SettingCDSDeleteModal').modal('toggle');
    });

});
