$(document).ready(function () {

    $('#CustomCounterListTable').DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        ajax: {
            "url": "/custom_counters/get/table",
            "type": "POST",
            "data": function (d) {
                d._token = $('meta[name="csrf-token"]').attr('content')
            },
        },
        columns: [
            {
                data: 'DT_RowIndex',
                name: 'DT_RowIndex',
                orderable: false,
                searchable: false,
            },
            {
                data: 'longname',
                name: 'longname',
                className: "text-left",
                render: function (data, type, row) {
                    return data + ' <small>(' + row.shortname + ')</small>';
                }
            },
            {
                data: 'board',
                name: 'board',
                className: "text-center",
            },
            {
                data: 'start_at',
                name: 'start_at',
                className: "text-center",
                render: function (data, type, row) {
                    return moment(data).format('DD/MM/YY h:mm A') + ' - ' + moment(row.end_at).format('DD/MM/YY h:mm A');
                }
            },
            {
                data: 'last',
                name: 'last',
                className: "text-center",
                render: function (data, type, row) {
                    return 'RM ' + data;
                }
            },
            {
                data: "counter_id",
                orderable: false,
                className: "text-center",
                render: function (data) {
                    let edit = '<button data-id="' + data + '" class="btn btn-sm d-sm-inline-block btn-primary m-1 CustomCounterEdit" title="Edit"><i class="fas fa-edit"></i></button>';
                    let config = '<a href="/custom_counters/' + data + '/config" class="btn btn-sm d-sm-inline-block btn-success m-1" title="Config"><i class="fas fa-cog"></i></a>';
                    let del = '';
                    if (['super admin', 'approver'].includes($('#user_role').data('role'))) {
                        del = '<button data-id="' + data + '" class="btn btn-sm d-sm-inline-block btn-danger m-1 CustomCounterDelete" title="Delete"><i class="fas fa-trash"></i></button>';
                    }
                    return edit + config + del;
                },
            },
        ],
        order: [
            [2, 'desc']
        ],
        pageLength: 50,
        language: {
            paginate: {
                previous: "<i class='fas fa-angle-left'></i>",
                next: "<i class='fas fa-angle-right'></i>"
            }
        }
    });

    $(document).on('click', '.CustomCounterEdit', function () {
        let id = $(this).data('id');
        $.ajax({
            type: 'POST',
            url: '/custom_counters/get/data',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                id: id,
            },
            success: function (result) {
                if (result) {
                    $('#CustomCounterEditID').val(result.counter_id);
                    $('#CustomCounterEditLongname').val(result.longname);
                    $('#CustomCounterEditShortname').val(result.shortname);
                    $('#CustomCounterEditCode').val(result.code);
                    $('#CustomCounterEditBoard').val(result.board);
                    $('#CustomCounterEditLast').val(result.last);
                    $('#CustomCounterEditStart').val(moment(result.start_at).format('YYYY-MM-DDTH:mm'));
                    $('#CustomCounterEditEnd').val(moment(result.end_at).format('YYYY-MM-DD\TH:mm'));
                    console.log(moment(result.start_at).format('YYYY-MM-DD\TH:mm'));
                    $('#CustomCounterEditModal').modal('toggle');
                }
            },
        });
    });


    $(document).on('click','.CustomCounterDelete', function(){
        let id = $(this).data("id");
        $('#SettingCustomCounterDeleteForm').attr('action', "/custom_counters/"+id);
        $('#SettingCustomCounterDeleteModal').modal('toggle');
    });
});
