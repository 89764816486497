$(document).ready(function () {

    $('#CounterListTable').DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        ajax: {
            "url": "/counters/get/table",
            "type": "POST",
            "data": function (d) {
                d._token = $('meta[name="csrf-token"]').attr('content')
            },
        },
        columns: [
            {
                data: 'DT_RowIndex',
                name: 'DT_RowIndex',
                orderable: false,
                searchable: false,
            },
            {
                data: 'code',
                name: 'code',
                className: "text-center",
            },
            {
                data: 'longname',
                name: 'longname',
                className: "text-left",
                render: function (data, type, row) {
                    return data + ' <small>(' + row.shortname + ')</small>';
                }
            },
            {
                data: 'board',
                name: 'board',
                className: "text-center",
            },
            {
                data: 'last',
                name: 'last',
                className: "text-center",
                render: function (data, type, row) {
                    return 'RM ' + data;
                }
            },
            {
                data: 'capped_percentage',
                name: 'capped_percentage',
                className: "text-center",
                render: function (data, type, row) {
                    let input = '<input type="number" style="width: 70px" data-id="'+row.counter_id+'" value="'+data+'" class="counter_percentage_edit">';
                    return input;
                }
            },
            {
                data: 'updated_at',
                name: 'updated_at',
                className: "text-center",
                render: function (data, type, row) {
                    return moment(data).format('DD/MM/YY h:mm A');
                }
            },
        ],
        order: [
            [2, 'desc']
        ],
        pageLength: 50,
        language: {
            paginate: {
                previous: "<i class='fas fa-angle-left'></i>",
                next: "<i class='fas fa-angle-right'></i>"
            }
        }
    });

    $(document).on('change', '.counter_percentage_edit', function () {
        let value = $(this).val();
        let id    = $(this).data('id');
        $.ajax({
            type: 'POST',
            url: '/counters/update',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                id: id,
                value: value,
            },
            success: function (result) {
                $.toast({
                    heading: result.status == true ? 'Success' : 'Failed',
                    text: result.msg,
                    showHideTransition: 'slide',
                    hideAfter: 2500,
                    icon: result.status == true ? 'success' : 'error',
                    position: 'top-right',
                });
            },
        });
    });

});
