$(document).ready(function () {
    $('[data-toggle="popover"]').popover({
        html: true
    });

    if ($(".oc_show_select").length > 0) {
        $(".oc_show_select").on("click", function () {
            if ($('input.oc_show_select').is(':checked')) {
                $(".oc_show").css('display', 'table-row');
            }
            else {
                $(".oc_show").css('display', 'none');
            }

        })
    }

    var MarginOfFinancing = 50;

    $('.cdd-table').parents('div.dataTables_wrapper').first().hide();
    $('.ipo-table').parents('div.dataTables_wrapper').first().hide();
    $('.esos-table').parents('div.dataTables_wrapper').first().hide();
    $('.pp-tables').parents('div.dataTables_wrapper').first().hide();


    $(document).on('click', '.BtnNext', function () {
        let thiss = $(this);
        let count = 0;
        let pageID = (thiss.data('page') - 1);

        $('#tabs-icons-text-' + pageID).find('input').each(function () {
            if (this.value.trim() == "" || this.value == null) {
                bootbox.alert({
                    centerVertical: true,
                    message: "Please complete all the required fields before proceeding!",
                });
                this.focus();
                count++;
                return false;
            }
        });

        $('#tabs-icons-text-' + pageID).find('select').each(function () {
            if (this.value.trim() == "" || this.value == null) {
                bootbox.alert({
                    centerVertical: true,
                    message: "Please complete all the required fields before proceeding!",
                });
                this.focus();
                count++;
                return false;
            }
        });

        if (count == 0) {
            for (let index = 0; index < 5; index++) {
                $('#tabs-icons-text-' + index).removeClass('show active');
                $('#tabs-icons-text-' + index + '-tab').removeClass('active');
            }
            if (thiss.data('page') == 3) {
                updateFinalFinancing();
            }
            $('#tabs-icons-text-' + thiss.data('page') + '-tab').addClass('active');
            $('#tabs-icons-text-' + thiss.data('page')).addClass('show active');
        }
        return false;
    });

    $(document).on('click', '.BtnPrevious', function () {
        let thiss = $(this);

        if (thiss.data('page') == 0) {
            location.reload();
            return false;
        }

        for (let index = 0; index < 5; index++) {
            $('#tabs-icons-text-' + index).removeClass('show active');
            $('#tabs-icons-text-' + index + '-tab').removeClass('active');
        }
        if (thiss.data('page') == 3) {
            updateFinalFinancing();
        }
        $('#tabs-icons-text-' + thiss.data('page') + '-tab').addClass('active');
        $('#tabs-icons-text-' + thiss.data('page')).addClass('show active');
    });

    $(document).on('click', '.no_product', function () {
        bootbox.alert({
            centerVertical: true,
            message: "This product is not available at the moment!",
        });
    });

    $(document).on('click', '.product_select', function () {
        $('#product').val($(this).data('product'));
        $('#product_id').val($(this).data('product-id'));
        $('.product_title').html(' - <u  class="text-primary">' + $(this).data('product') + '</u>');
        $('.product_title_header').html($(this).data('product'));
        let isManual = $(this).data("manualform");
        // console.log(isManual);
        $.ajax({
            type: 'POST',
            url: '/applications/get/inputs',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                product: $(this).data('product'),
                userrole: $(this).data('userrole'),
            },
            success: function (result) {
                $('#appendInputs').html('');
                result.forEach(element => {
                    let aa = '';
                    if (!isManual) {
                        aa = (element.key != 'others_file') ? 'required' : '';
                    }
                    let ab = (element.key != 'others_file') ? '<span class="text-danger w-100">*</span>' : '';
                    let html = '<div class="col-lg-6 col-sm-12 mb-4">' +
                        '<div class="custom-file">' +
                        '<label for="nric_file">' + element.name + ab + ' </label>' +
                        '<input type="file" class="form-control w-100" name="' + element.key + '" id="' + element.key + '" accept=".pdf,.jpeg,.jpg,.JPG,.png,.PNG,.gif,.eps" ' + aa + '>' +
                        '</div>' +
                        '</div>';
                    $('#appendInputs').append(html);
                });
            },
        });

        $.ajax({
            type: 'POST',
            url: '/applications/get/defaults',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                product: $(this).data('product'),
            },
            success: function (result) {
                if (result) {
                    MarginOfFinancing = result.margin_of_finance;
                    $('#cds_fee_gateway').text(result.cds_fee);
                    $('.check_cds_fee_modal').hide();
                    $('.check_cds_fee_submit').show();
                    $('#Tenure').html('<option value="" selected disabled> Choose Tenure </option>')
                    result.available_tenure.forEach(element => {
                        $('#Tenure').append('<option value="' + element + '">' + element + ' month </option>');
                    });
                }
            },
        });
        $('#tabs-icons-text-0').removeClass('show active');
        $('#tabs-icons-text-0-tab').removeClass('active');
        $('#tabs-icons-text-1-tab').addClass('active');
        $('#tabs-icons-text-1').addClass('show active');
        CompanyListingTable.ajax.reload();
        $('.cddNote').hide();


        /* test */
        if ($('#product').val() == 'Cash Drawdown') {
            $('.cdd-table').parents('div.dataTables_wrapper').first().show();
            $('.ipo-table').parents('div.dataTables_wrapper').first().hide();
            $('.esos-table').parents('div.dataTables_wrapper').first().hide();
            $('.pp-tables').parents('div.dataTables_wrapper').first().hide();
            $('.DTotalShareCapped').show();
            $('.UserShareAlloted').hide();
            $('.totalValueText').html('Total Capped Share Value<span class="text-danger">*</span>');
            $('.cddNote').show();
            $('.ListCompanyLastLabel').text('Market Price');
            $('.UserShareValueLabel').text('No. of Shares Pledged');

            $('#UserShareValue').attr('placeholder', 'No. of Shares Pledged');

            $('.CalcTotalShareValueLabel').text('Total Share Value');
            $('#CalcTotalShareValue').attr('placeholder', 'Share Values ');

        } else if ($('#product').val() == 'ESOS') {
            $('.cdd-table').parents('div.dataTables_wrapper').first().hide();
            $('.ipo-table').parents('div.dataTables_wrapper').first().hide();
            $('.pp-tables').parents('div.dataTables_wrapper').first().hide();
            $('.esos-table').parents('div.dataTables_wrapper').first().show();
            $('.DTotalShareCapped').hide();
            $('.UserShareValue').hide();
            $('.esos-filled').show();
            $('.esos-filled').addClass('checkModalInputs');
            $('#UserShareValue').removeClass('checkModalInputs');
            $('.totalValueText').html('Total Subscriptions Value<span class="text-danger">*</span>');

        } else {
            $(".show_ipo").hide();
            $('.cdd-table').parents('div.dataTables_wrapper').first().hide();
            $('.ipo-table').parents('div.dataTables_wrapper').first().show();
            $('.esos-table').parents('div.dataTables_wrapper').first().hide();
            $('.pp-tables').parents('div.dataTables_wrapper').first().hide();
            $('.UserShareAlloted').show();
            $('.DTotalShareCapped').hide();
            $('.totalValueText').html('Subscription Amount<span class="text-danger">*</span>');


            $('.ListCompanyLastLabel').text('IPO Price');
            $('.UserShareValueLabel').text('No. of Shares Subscribed');
            $('.CalcTotalShareValueLabel').text('Subscription Amount ');

            $('#UserShareValue').attr('placeholder', 'No. of Shares Subscribed');
            $('#CalcTotalShareValue').attr('placeholder', 'Subscription Amount ');

            if ($('#product').val() == 'IPO Financing') {
                $(".show_ipo").show();
                $('.ListCompanyNameLabel').text('Name of Company (Stock)');
                $('.ListCompanyCode').hide();
            } else {
                $('.ListCompanyNameLabel').text('Name of Listed Company (Stock)');
                $('.ListCompanyCode').show();
            }

            if ($('#product').val() == 'Private Placement Financing') {

                $('.pp-tables').parents('div.dataTables_wrapper').first().show();
                $('.cdd-table').parents('div.dataTables_wrapper').first().hide();
                $('.ipo-table').parents('div.dataTables_wrapper').first().hide();
                $('.esos-table').parents('div.dataTables_wrapper').first().hide();

                $('.totalValueText').html('Total Share Consideration<span class="text-danger">*</span>');

                $('#UserShareAlloted').prop('readonly', true);
                let inputs = '<label for="example-text-input" class="form-control-label">Placement Price (RM)</label> <input class="form-control form-control-sm" type="number" step="0.01" placeholder="Placement Price" id="ListCompanyLast"> <input type="hidden" id="ListCompanyLast2">';
                $('.ListCompanyLast').remove();
                $('.pp-filled').html(inputs);
                $('.pp-filled').show();

                $('.UserShareAllotedLabel').text('No. of Shares Alloted/Offered');
                $('.UserShareValueLabel').text('No. of Shares Subscribed');
                $('.CalcTotalShareValueLabel').text('Total Consideration');

                $('#UserShareAlloted').attr('placeholder', 'No. of Shares Alloted/Offered');
                $('#UserShareValue').attr('placeholder', 'No. of Shares Subscribed');
                $('#CalcTotalShareValue').attr('placeholder', 'Total Consideration');
            }
        }
        /* test */


        // if ($('#product').val() == 'Cash Drawdown') {
        //     $('.cdd-table').parents('div.dataTables_wrapper').first().show();
        //     $('.ipo-table').parents('div.dataTables_wrapper').first().hide();
        //     $('.esos-table').parents('div.dataTables_wrapper').first().hide();
        //     $('.pp-tables').parents('div.dataTables_wrapper').first().hide();
        //     $('.DTotalShareCapped').show();
        //     $('.totalValueText').html('Total Capped Share Value<span class="text-danger">*</span>');
        //     $('.cddNote').show();
        //     $('.UserShareAlloted').hide();
        //     $('.ListCompanyLastLabel').text('Market Price');

        // } else if ($('#product').val() == 'ESOS') {
        //     $('.cdd-table').parents('div.dataTables_wrapper').first().hide();
        //     $('.ipo-table').parents('div.dataTables_wrapper').first().hide();
        //     $('.pp-tables').parents('div.dataTables_wrapper').first().hide();
        //     $('.esos-table').parents('div.dataTables_wrapper').first().show();
        //     $('.DTotalShareCapped').hide();
        //     $('.UserShareValue').hide();
        //     $('.esos-filled').show();
        //     $('.esos-filled').addClass('checkModalInputs');
        //     $('#UserShareValue').removeClass('checkModalInputs');
        //     $('.totalValueText').html('Total Subscriptions Value<span class="text-danger">*</span>');

        // } else {
        //     $('.cdd-table').parents('div.dataTables_wrapper').first().hide();
        //     $('.ipo-table').parents('div.dataTables_wrapper').first().show();
        //     $('.esos-table').parents('div.dataTables_wrapper').first().hide();
        //     $('.pp-tables').parents('div.dataTables_wrapper').first().hide();
        //     $('.UserShareAlloted').show();
        //     $('.DTotalShareCapped').hide();
        //     $('.totalValueText').html('Total Shares Value<span class="text-danger">*</span>');


        //     $('.ListCompanyLastLabel').text('IPO Price');
        //     $('.UserShareValueLabel').text('No. of Shares Subscribed');
        //     $('.CalcTotalShareValueLabel').text('Subscription Amount ');

        //     $('#UserShareValue').attr('placeholder', 'No. of Shares Subscribed');
        //     $('#CalcTotalShareValue').attr('placeholder', 'Subscription Amount ');

        //     if ($('#product').val() == 'IPO Financing') {
        //         $('.ListCompanyNameLabel').text('Name of Company (Stock)');
        //         $('.ListCompanyCode').hide();
        //     } else {
        //         $('.ListCompanyNameLabel').text('Name of Listed Company (Stock)');
        //         $('.ListCompanyCode').show();
        //     }

        //     if ($('#product').val() == 'Private Placement Financing') {

        //         $('.pp-tables').parents('div.dataTables_wrapper').first().show();
        //         $('.cdd-table').parents('div.dataTables_wrapper').first().hide();
        //         $('.ipo-table').parents('div.dataTables_wrapper').first().hide();
        //         $('.esos-table').parents('div.dataTables_wrapper').first().hide();

        //         $('.totalValueText').html('Total Share Consideration<span class="text-danger">*</span>');

        //         $('#UserShareAlloted').prop('readonly', true);
        //         let inputs = '<label for="example-text-input" class="form-control-label">Placement Price (RM)</label> <input class="form-control form-control-sm" type="number" step="0.01" placeholder="Placement Price" id="ListCompanyLast"> <input type="hidden" id="ListCompanyLast2">';
        //         $('.ListCompanyLast').remove();
        //         $('.pp-filled').html(inputs);
        //         $('.pp-filled').show();

        //         $('.UserShareAllotedLabel').text('No. of Shares Alloted/Offered');
        //         $('.UserShareValueLabel').text('No. of Shares Subscribed');
        //         $('.CalcTotalShareValueLabel').text('Total Consideration');

        //         $('#UserShareAlloted').attr('placeholder', 'No. of Shares Alloted/Offered');
        //         $('#UserShareValue').attr('placeholder', 'No. of Shares Subscribed');
        //         $('#CalcTotalShareValue').attr('placeholder', 'Total Consideration');
        //     }
        // }

    });

    var CDDShareListTable = $('.cdd-table').DataTable({
        responsive: true,
        searching: false,
        bLengthChange: false,
        bInfo: false,
        language: {
            paginate: {
                previous: "<i class='fas fa-angle-left'></i>",
                next: "<i class='fas fa-angle-right'></i>"
            }
        },
        "drawCallback": function (settings) {
            var api = this.api();

            // Output the data for the visible rows to the browser's console
            var data = api.rows({ page: 'current' }).data();
            var calc = 0;
            if ($("#checkEditMargin").length > 0) {
                for (var i = 0; i < data.length; i++) {
                    var c = data[i][3];
                    c = c.replaceAll(",", "");
                    calc += parseFloat(c.replaceAll("RM ", ""));
                    // console.log(calc);
                }
                $("#total_share_value").val(parseFloat(calc.toFixed(2)));
                if (parseFloat(calc.toFixed(2)) >= parseFloat($("#checkEditMargin").val().replaceAll(",", ""))) {
                    // $("#btn-edit-save-app").prop("disabled",false);
                    $("#total_share_value").val(parseFloat(calc.toFixed(2)));
                    $("#btn-edit-save-app").prop("disabled", false);
                }
                else {
                    if (calc.toFixed(2) == 0) {
                        $("#btn-edit-save-app").prop("disabled", true);
                    }
                    else {
                        $("#btn-edit-save-app").prop("disabled", true);
                    }

                }
            }
            else {
                for (var i = 0; i < data.length; i++) {
                    var c = data[i][6];
                    c = c.replaceAll(",", "");
                    calc += parseFloat(c.replaceAll("RM ", ""));
                }
            }
        }
    });
    // CDDShareListTable.on( 'draw', function (e,f) {
    //     console.log(e,f);
    //     console.log( 'Redraw occurred at: '+new Date().getTime() );
    // });
    var IPOShareListTable = $('.ipo-table').DataTable({
        responsive: true,
        searching: false,
        bLengthChange: false,
        bInfo: false,
        language: {
            paginate: {
                previous: "<i class='fas fa-angle-left'></i>",
                next: "<i class='fas fa-angle-right'></i>"
            }
        }
    });
    var ESOSShareListTable = $('.esos-table').DataTable({
        responsive: true,
        searching: false,
        bLengthChange: false,
        bInfo: false,
        language: {
            paginate: {
                previous: "<i class='fas fa-angle-left'></i>",
                next: "<i class='fas fa-angle-right'></i>"
            }
        }
    });
    var PPShareListTable = $('.pp-tables').DataTable({
        responsive: true,
        searching: false,
        bLengthChange: false,
        bInfo: false,
        language: {
            paginate: {
                previous: "<i class='fas fa-angle-left'></i>",
                next: "<i class='fas fa-angle-right'></i>"
            }
        }
    });

    $('#ListedCompany-List').on('show.bs.modal', function (e) {
        $('#myModal').modal('hide');
        $('body').css("overflow", "hidden");
        $(this).css("overflow-y", "auto");
    })
        .on('hide.bs.modal', function (e) {
            // @todo reload the job
            $('#ListedCompany')
                .modal('show')
                .css("overflow-y", "auto");
        });

    $('#ListedCompany').on('show.bs.modal', function (e) {
        // @todo reload the job
        $('body').css("overflow", "hidden");
    })
        .on('hide.bs.modal', function (e) {
            // @todo reload the job
            $('body').css("overflow", "visible");
        });

    $(document).on('click', '.SelectListedCompany', function () {
        let _token = $('meta[name="csrf-token"]').attr('content');
        let id = $(this).data(id);
        $.ajax({
            type: 'POST',
            url: '/listed_company/get/data',
            data: {
                _token: _token,
                product: $('#product').val(),
                id: id,
            },
            success: function (result) {
                if (result.code) {
                    $('#ListCompanyCode').val(result.code);
                } else {
                    $('#ListCompanyCode').val('-');
                }
                $('#ListCompanyName').val(result.longname);
                $('#ListCompanyLast').val('RM ' + result.last);
                if ($('#product').val() == 'Cash Drawdown') {
                    $('#capped_price').val('RM ' + (result.last * result.capped_percentage) / 100);
                }
                if ($('#product').val() == 'Private Placement Financing') {
                    $('#ListCompanyLast2').val('RM ' + result.last);
                }
                $('#UserShareValue').val('');
                $('#CalcTotalShareValue').val('');
                $('#ListedCompany-List').modal('hide');

                if (result.config) {
                    MarginOfFinancing = result.config.margin_of_finance;
                    $('#cds_fee_gateway').text(result.config.cds_fee);
                    $('.check_cds_fee_modal').hide();
                    $('.check_cds_fee_submit').show();
                    $('#Tenure').html('<option value="" selected disabled> Choose Tenure </option>')
                    result.config.available_tenure.forEach(element => {
                        $('#Tenure').append('<option value="' + element + '">' + element + ' month </option>');
                    });
                }
            },
        });
    });

    $(document).on('input', '#UserShareValue', function () {
        let currentValue = $('#ListCompanyLast').val();
        let result = 0;
        if (currentValue.length > 0) {
            if ($('#product').val() == 'Private Placement Financing') {
                result = $(this).val().replace(/,/g, '') * parseFloat(currentValue);
            } else {
                result = $(this).val().replace(/,/g, '') * parseFloat(currentValue.split('RM ')[1]);
            }
        }
        $('#CalcTotalShareValue').val('RM ' + result.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

        if ($('#product').val() == 'Cash Drawdown') {
            currentValue = $('#capped_price').val();
            if (currentValue.length > 0) {
                result = $(this).val().replace(/,/g, '') * parseFloat(currentValue.split('RM ')[1]);
            }
            $('#capped_value').val('RM ' + result.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        }

        if ($('#product').val() == 'Private Placement Financing') {
            $('#UserShareAlloted').val($(this).val().replace(/,/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        }
    });

    $(document).on('input', '#ExercisePrice', function () {
        if ($('#product').val() == 'ESOS') {
            if (parseFloat($(this).val().replace(/,/g, '')) > parseFloat($('#ListCompanyLast').val().split('RM ')[1].replace(/,/g, ''))) {
                bootbox.alert({
                    centerVertical: true,
                    message: "Max Exercise Price must be <b>" + $('#ListCompanyLast').val() + "</b> or less.",
                });
                $('#ExercisePrice').val('');
                return false;
            }
        }
    });

    $(document).on('input', '#UserShareValue', function () {
        if ($('#product').val() == 'IPO Financing') {
            if (parseFloat($(this).val().replace(/,/g, '')) > parseFloat($('#UserShareAlloted').val().replace(/,/g, ''))) {
                bootbox.alert({
                    centerVertical: true,
                    message: "Max No. of Shares must be <b>" + $('#UserShareAlloted').val() + "</b> or less.",
                });
                $('#UserShareValue').val('');
                return false;
            }
        }
    });

    $(document).on('input', '#ListCompanyLast', function () {
        if ($('#product').val() == 'Private Placement Financing') {
            if ($('#UserShareValue').val().length > 0) {

                let currentValue = $('#ListCompanyLast').val();
                let result = 0;
                if (currentValue.length > 0) {
                    result = $('#UserShareValue').val().replace(/,/g, '') * parseFloat(currentValue);
                }

                $('#CalcTotalShareValue').val('RM ' + result.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
            }
        }
    });

    $(document).on('input', '#UserShareExercise', function () {
        let currentValue = $('#ListCompanyLast').val();
        let result = 0;
        if (currentValue.length > 0) {
            if ($('#product').val() == 'Private Placement Financing') {
                result = $(this).val().replace(/,/g, '') * parseFloat(currentValue);
            } else {
                result = $(this).val().replace(/,/g, '') * parseFloat(currentValue.split('RM ')[1]);
            }
        }

        if ($('#product').val() == 'ESOS') {
            if (parseFloat($(this).val().replace(/,/g, '')) > parseFloat($('#UserShareEntitle').val().replace(/,/g, ''))) {
                bootbox.alert({
                    centerVertical: true,
                    message: "Max No. of Shares Exercise must be <b>" + $('#UserShareEntitle').val() + "</b> or less.",
                });
                $('#UserShareExercise').val('');
                return false;
            }
        }

        $('#CalcTotalShareValue').val('RM ' + result.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

    });

    $(document).on('click', '#AddShareToTable', function () {

        let thiss = $(this);
        let count = 0;
        $('.checkModalInputs').each(function () {
            if (this.value == "") {
                bootbox.alert({
                    centerVertical: true,
                    message: "Please complete all the required fields before proceeding!",
                });
                this.focus();
                count++;
                return false;
            }
        });

        $('#FinancingAmount').val('');

        if (count == 0) {
            let data;
            if ($('#product').val() == 'Cash Drawdown') {

                if ($("#checkEditMargin").length > 0) {
                    CDDShareListTable.row.add([
                        $('#ListCompanyName').val(),
                        $('#ListCompanyCode').val(),
                        $('#UserShareValue').val(),
                        // $('#ListCompanyLast').val(),
                        'RM ' + $('#CalcTotalShareValue').val().replace("RM", ""),
                        // $('#capped_price').val(),
                        // $('#capped_value').val(),
                        '<button data-code="' + $('#ListCompanyCode').val() + '" data-id="' + $('#ListCompanyName').val() + '" class="btn btn-sm d-sm-inline-block btn-danger m-1 DeleteSelectedListedCompany" title="Delete"><i class="fas fa-trash-alt"></i></button>'
                    ]).draw(false);
                }
                else {
                    CDDShareListTable.row.add([
                        $('#ListCompanyName').val(),
                        $('#ListCompanyCode').val(),
                        $('#UserShareValue').val(),
                        $('#ListCompanyLast').val(),
                        $('#CalcTotalShareValue').val(),
                        $('#capped_price').val(),
                        $('#capped_value').val(),
                        '<button data-code="' + $('#ListCompanyCode').val() + '" data-id="' + $('#ListCompanyName').val() + '" class="btn btn-sm d-sm-inline-block btn-danger m-1 DeleteSelectedListedCompany" title="Edit"><i class="fas fa-trash-alt"></i></button>'
                    ]).draw(false);
                }
                data = {
                    'company_name': $('#ListCompanyName').val(),
                    'code': $('#ListCompanyCode').val(),
                    'last': $('#ListCompanyLast').val(),
                    'units': $('#UserShareValue').val().replace(/,/g, ''),
                    'capped_price': $('#capped_price').val(),
                    'capped_value': $('#capped_value').val(),
                    'total_share': $('#CalcTotalShareValue').val().replace(/,/g, ''),
                };
            } else if ($('#product').val() == 'ESOS') {
                ESOSShareListTable.row.add([
                    $('#ListCompanyName').val(),
                    $('#ListCompanyCode').val(),
                    $('#UserShareEntitle').val(),
                    $('#UserShareExercise').val(),
                    'RM ' + $('#ExercisePrice').val().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    'RM ' + ($('#ExercisePrice').val().replace(/,/g, '') * $('#UserShareExercise').val().replace(/,/g, '')).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    $('#ListCompanyLast').val(),
                    $('#CalcTotalShareValue').val(),
                    '<button data-code="' + $('#ListCompanyCode').val() + '" data-id="' + $('#ListCompanyName').val() + '" class="btn btn-sm d-sm-inline-block btn-danger m-1 DeleteSelectedListedCompany" title="Edit"><i class="fas fa-trash-alt"></i></button>'
                ]).draw(false);
                data = {
                    'company_name': $('#ListCompanyName').val(),
                    'code': $('#ListCompanyCode').val(),
                    'ShareEntitle': $('#UserShareEntitle').val(),
                    'ShareExercise': $('#UserShareExercise').val(),
                    'ExercisePrice': $('#ExercisePrice').val(),
                    'SubscriptionValue': 'RM ' + ($('#ExercisePrice').val().replace(/,/g, '') * $('#UserShareExercise').val().replace(/,/g, '')).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    'last': $('#ListCompanyLast').val(),
                    'units': $('#UserShareExercise').val().replace(/,/g, ''),
                    'total_share': $('#CalcTotalShareValue').val().replace(/,/g, ''),
                };
            } else if ($('#product').val() == 'Private Placement Financing') {
                PPShareListTable.row.add([
                    $('#ListCompanyName').val(),
                    $('#ListCompanyCode').val(),
                    $('#UserShareAlloted').val(),
                    $('#UserShareValue').val(),
                    $('#ListCompanyLast').val(),
                    $('#CalcTotalShareValue').val(),
                    '<button data-code="' + $('#ListCompanyCode').val() + '" data-id="' + $('#ListCompanyName').val() + '" class="btn btn-sm d-sm-inline-block btn-danger m-1 DeleteSelectedListedCompany" title="Edit"><i class="fas fa-trash-alt"></i></button>'
                ]).draw(false);
                data = {
                    'company_name': $('#ListCompanyName').val(),
                    'code': $('#ListCompanyCode').val(),
                    'last': $('#ListCompanyLast').val(),
                    'last_ori': $('#ListCompanyLast2').val(),
                    'units_alloted': $('#UserShareAlloted').val().replace(/,/g, ''),
                    'units': $('#UserShareValue').val().replace(/,/g, ''),
                    'total_share': $('#CalcTotalShareValue').val().replace(/,/g, ''),
                };
            } else {
                IPOShareListTable.row.add([
                    $('#ListCompanyName').val(),
                    $('#UserShareAlloted').val(),
                    $('#UserShareValue').val(),
                    $('#ListCompanyLast').val(),
                    $('#CalcTotalShareValue').val(),
                    '<button data-code="' + $('#ListCompanyCode').val() + '" data-id="' + $('#ListCompanyName').val() + '" class="btn btn-sm d-sm-inline-block btn-danger m-1 DeleteSelectedListedCompany" title="Edit"><i class="fas fa-trash-alt"></i></button>'
                ]).draw(false);
                data = {
                    'company_name': $('#ListCompanyName').val(),
                    'code': $('#ListCompanyCode').val(),
                    'last': $('#ListCompanyLast').val(),
                    'units_alloted': $('#UserShareAlloted').val().replace(/,/g, ''),
                    'units': $('#UserShareValue').val().replace(/,/g, ''),
                    'total_share': $('#CalcTotalShareValue').val().replace(/,/g, ''),
                };
            }

            let lastValue = $('#share_data').val();
            if (lastValue.length > 0) {
                let data2 = JSON.parse(lastValue);
                data2.push(data);
                $('#share_data').val(JSON.stringify(data2));
            } else {
                let data2 = [];
                data2.push(data);
                $('#share_data').val(JSON.stringify(data2));
                $('#FinancingAmount').prop('disabled', false);
            }

            let share_price = ($('#product').val() == 'Private Placement Financing') ? 'Placement Price' : 'Share Price<br><span style="font-size: 12px">(Previous day closing price)</span>';

            let extra = '';
            if ($('#product').val() == 'IPO Financing') {
                share_price = 'IPO Price';
                extra = '<div class="row">' +
                    '<div class="col-6"><p class="font-weight-bold">No. of Shares Alloted</p></div>' +
                    '<div class="col-6"><p>: ' + data.units_alloted.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' Units</p></div>' +
                    '</div>';
            }
            if ($('#product').val() == 'ESOS') {
                extra = '<div class="row">' +
                    '<div class="col-6"><p class="font-weight-bold">No. of Shares Entitlement</p></div>' +
                    '<div class="col-6"><p>: ' + data.ShareEntitle + '</p></div>' +
                    '</div>' +
                    '<div class="row">' +
                    '<div class="col-6"><p class="font-weight-bold">No. of Shares Exercise</p></div>' +
                    '<div class="col-6"><p>: ' + data.ShareExercise + '</p></div>' +
                    '</div>' +
                    '<div class="row">' +
                    '<div class="col-6"><p class="font-weight-bold">Exercise Price</p></div>' +
                    '<div class="col-6"><p>: RM ' + data.ExercisePrice + '</p></div>' +
                    '</div>' +
                    '<div class="row">' +
                    '<div class="col-6"><p class="font-weight-bold">Exercise Value</p></div>' +
                    '<div class="col-6"><p>: ' + data.SubscriptionValue + '</p></div>' +
                    '</div>';
            } else if ($('#product').val() == 'Private Placement Financing') {
                extra = '<div class="row">' +
                    '<div class="col-6"><p class="font-weight-bold">Share Price<br><span style="font-size: 12px">(Previous day closing price)</span></p></div>' +
                    '<div class="col-6"><p>: ' + $('#ListCompanyLast2').val() + '</p></div>' +
                    '</div>';
            }

            let share_prices = ($('#product').val() == 'Private Placement Financing') ? 'RM ' + $('#ListCompanyLast').val() : $('#ListCompanyLast').val();

            let share_price2 = ($('#product').val() == 'Private Placement Financing') ? 'Subscription Value' : 'Shares Value';

            ($('#product').val() == 'Private Placement Financing') ? $('.DTotalShareLabel').text('Total Subscription Value') : $('.DTotalShareLabel').text('Market Value');

            let title2 = ($('#product').val() == 'IPO Financing') ? 'Name of Company (Stock)' : 'Name of Listed Company (Stock)';

            let html = '<div class="card shadow mb-3" id="' + data.code + '-card">' +
                '<div class="card-body m-0 bg-white">' +
                '<div class="row">' +
                '<div class="col-6"><p class="font-weight-bold">' + title2 + '</p></div>' +
                '<div class="col-6"><p>: ' + data.company_name + '</p></div>' +
                '</div>' +
                extra +
                '<div class="row">' +
                '<div class="col-6"><p class="font-weight-bold">No. of Shares Pledged</p></div>' +
                '<div class="col-6"><p>: ' + data.units.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' Units</p></div>' +
                '</div>' +
                '<div class="row">' +
                '<div class="col-6"><p class="font-weight-bold">' + share_price + '</p></div>' +
                '<div class="col-6"><p>: ' + share_prices + '</p></div>' +
                '</div>' +
                '<div class="row">' +
                '<div class="col-6"><p class="font-weight-bold">' + share_price2 + '</p></div>' +
                '<div class="col-6"><p>: ' + $('#CalcTotalShareValue').val() + '</p></div>' +
                '</div>' +
                '</div>' +
                '</div>';
            $('#collateral-div').append(html);

            updateShareValue();
            $('#DTotalShare').text($('#TotalShareValue').val());
            $('#DCDSAcc').text($('#cds_acc_no').val());

            $('#ListedCompany').modal('hide');
            $('#ListCompanyName').val('');
            $('#ListCompanyCode').val('');
            $('#ListCompanyLast').val('');
            $('#UserShareValue').val('');
            $('#CalcTotalShareValue').val('');

            if ($('#product').val() == 'IPO Financing') {
                if ($('#share_data').val().length > 0) {
                    $('.add-Counter').hide();
                } else {
                    $('.add-Counter').show();
                }
            }
        }
    });

    $(document).on('change', '#cds_acc_no', function () {
        $('#DCDSAcc').text($(this).val());
    });

    $(document).on('change', '#FinancingAmount', function () {
        $('#ApplyFinancingAmount').val('RM ' + parseFloat($(this).val()).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    });

    $(document).on('change', '#FinancingAmount', function () {
        if ($('#TotalShareValue').val().length > 0) {
            let value = (parseFloat($(this).val().replace(/,/g, '')) / parseFloat($('#TotalShareValue').val().split('RM ')[1].replace(/,/g, ''))) * 100;
            if (parseFloat($('#FinancingAmount').val().replace(/,/g, '')) > parseFloat($('#MaxFinancingValue').val().split('RM ')[1].replace(/,/g, ''))) {
                bootbox.alert({
                    centerVertical: true,
                    message: "The amount that you entered exceeded the maximum available loan amount of RM " + $('#MaxFinancingValue').val() + ". Please enter a valid amount.",
                });
                $('#MarginMOF').val('');
                $(this).val('');
            } else {
                $('#MarginMOF').val(value.toFixed(2) + '%');
                $('#DMarginFinancing').text(value.toFixed(2) + '%');
                $('#DFinancingAmount').text(parseFloat($(this).val()).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                $('.DFinancingAmountIPO').text(parseFloat($(this).val()).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                $('#DFacility').text((($(this).val().replace(/,/g, '') * 2) / 100).toFixed(2));
                $('#DStamDuty').text((($(this).val().replace(/,/g, '') * 0.5) / 100).toFixed(2));
                $('#BalanceSubscriptionAmount').val("RM " + (parseFloat($('#TotalShareValue').val().split('RM ')[1].replace(/,/g, '')) - parseFloat($(this).val().replace(/,/g, ''))).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
            }
        }
    });

    function updateShareValue() {
        let lastValue = $('#share_data').val();
        if (lastValue.length > 0) {
            let data = JSON.parse(lastValue);
            let totalValue = 0;

            if ($('#product').val() == 'Cash Drawdown') {
                data.forEach(element => {
                    totalValue += parseFloat(element.capped_value.split('RM ')[1].replace(/,/g, ''));
                });
            } else if ($('#product').val() == 'ESOS') {
                data.forEach(element => {
                    totalValue += parseFloat(element.total_share.split('RM ')[1].replace(/,/g, ''));
                });
            } else {
                data.forEach(element => {
                    totalValue += parseFloat(element.total_share.split('RM ')[1].replace(/,/g, ''));
                });
            }

            $('#TotalShareValue').val('RM ' + totalValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
            $('#MaxMarginMOF').val(MarginOfFinancing + '%');
            // console.log(totalValue, MarginOfFinancing,((totalValue * MarginOfFinancing) / 100).toFixed(2));
            $('#MaxFinancingValue').val('RM ' + ((totalValue * MarginOfFinancing) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
            $('#MarginMOF').val('');
        } else {
            $('#TotalShareValue').val('');
            $('#MaxMarginMOF').val('');
            $('#MaxFinancingValue').val('');
            $('#MarginMOF').val('');
        }
    }

    $(document).on('click', '.DeleteSelectedListedCompany', function () {
        let data = JSON.parse($('#share_data').val());
        let value = $(this).data('id');
        data = data.filter(company_name => company_name.company_name != value);
        if (data.length > 0) {
            $('#share_data').val(JSON.stringify(data));
        } else {
            $('#share_data').val('');
            $('#FinancingAmount').val('');
            $('#FinancingAmount').prop('disabled', true);
        }

        if ($('#product').val() == 'Cash Drawdown') {
            CDDShareListTable.rows(function (idx, data, node) {
                // console.log('delete',data);
                return data[0] === value;
            }).remove().draw();
        } else if ($('#product').val() == 'ESOS') {
            ESOSShareListTable.rows(function (idx, data, node) {
                return data[0] === value;
            }).remove().draw();
        } else if ($('#product').val() == 'Private Placement Financing') {
            PPShareListTable.rows(function (idx, data, node) {
                return data[0] === value;
            }).remove().draw();
        } else {
            IPOShareListTable.rows(function (idx, data, node) {
                return data[0] === value;
            }).remove().draw();
        }
        $('#' + $(this).data('code') + '-card').remove();

        // updateShareValue();
        if ($('#product').val() == 'IPO Financing') {
            if ($('#share_data').val().length > 0) {
                $('.add-Counter').hide();
            } else {
                $('.add-Counter').show();
            }
        }
    });

    $(document).on('click', '.PlanSelect', function () {
        if ($(this).data('plan') == 1) {
            $('.PlanSelect').each(function () {
                if ($(this).data('plan') == 1) {
                    $(this).prop('disabled', true);
                } else {
                    $(this).prop('disabled', false);
                }
            });
            $('#Tenure').val('3');
            $('#DPlan').text('Short Term (Flexible Plan)');
            $('#DTenure').text('3 Months');
        } else if ($(this).data('plan') == 2) {
            $('.PlanSelect').each(function () {
                if ($(this).data('plan') == 2) {
                    $(this).prop('disabled', true);
                } else {
                    $(this).prop('disabled', false);
                }
            });
            $('#Tenure').val('12');
            $('#DPlan').text('Term Loan (Tawarruq)');
            $('#DTenure').text('12 Months');
        }
    });

    var CompanyListingTable = $('#CompanyListingTable').DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        searching: false,
        bLengthChange: false,
        bInfo: false,
        ajax: {
            "url": "/listed_company/search/ajax",
            "type": "POST",
            "data": function (d) {
                d._token = $('meta[name="csrf-token"]').attr('content');
                d.product = $('#product').val();
                d.search = $('#CompanySearch').val();
            },
        },
        columns: [{
            data: 'longname',
            name: 'longname',
        },
        {
            data: 'code',
            name: 'code',
            className: "text-center",
        },
        {
            data: 'last',
            name: 'last',
            className: "text-center",
        },
        {
            data: "id",
            orderable: false,
            className: "text-center",
            render: function (data, type, row) {
                if (row.id != null) {
                    return type === 'display' ?
                        '<button data-id="' + data + '" class="btn btn-sm d-sm-inline-block btn-primary m-1 SelectListedCompany" title="Edit">Select</button>' :
                        data;
                } else {
                    return '';
                }
            },
        },
        ],
        order: [
            [0, 'asc']
        ],
        pageLength: 10,
        language: {
            paginate: {
                previous: "<i class='fas fa-angle-left'></i>",
                next: "<i class='fas fa-angle-right'></i>"
            }
        }
    });

    $(document).on('input', '#CompanySearch', function () {
        CompanyListingTable.ajax.reload();
    });


    $(document).on('click', '.add-Counter', function () {

        if ($('#product').val() == 'ESOS') {
            if ($('#share_data').val() != '') {
                let lastValue = $('#share_data').val();
                if (lastValue.length > 0) {
                    let data = JSON.parse(lastValue);
                    data.forEach(element => {
                        $('#ListCompanyName').val(element.company_name);
                        $('#ListCompanyCode').val(element.code);
                        $('#ListCompanyLast').val(element.last);
                        $('#UserShareValue').val('');
                        $('#CalcTotalShareValue').val('');
                        $('#UserShareEntitle').val('');
                        $('#UserShareExercise').val('');
                        $('#ExercisePrice').val('');
                    });
                }
                $('#add-Counter2').hide();
                $('#add-Counter2').prop('disabled', true);
            } else {
                $('#add-Counter2').show();
                $('#add-Counter2').prop('disabled', false);
            }
        }
    });

    function updateFinalFinancing(isEdit) {
        isEdit = (typeof isEdit !== 'undefined') ? true : false;
        let loading = bootbox.dialog({
            message: '<div class="text-center"><i class="fa fa-spin fa-spinner"></i> Calculating...</div>',
            centerVertical: true,
            closeButton: false
        });
        let _data = {
            _token: $('meta[name="csrf-token"]').attr('content'),
            product: $('#product').val(),
            id: $('#application_id').val(),
            financing_amount: $('#FinancingAmount').val(),
            total_share: $('#TotalShareValue').val(),
            margin_of_Financing: $('#MarginMOF').val(),
            shares_data: $('#share_data').val(),
            tenure: '',
        };
        if ($('#Tenure').length > 0) {
            _data = {
                _token: $('meta[name="csrf-token"]').attr('content'),
                id: $('#application_id').val(),
                product: $('#product').val(),
                financing_amount: $('#FinancingAmount').val(),
                total_share: $('#TotalShareValue').val(),
                margin_of_Financing: $('#MarginMOF').val(),
                shares_data: $('#share_data').val(),
                tenure: $('#Tenure').val(),
            };
        }
        if (isEdit) {
            _data = {
                //default params
                _token: $('meta[name="csrf-token"]').attr('content'),
                product: $('#product').val(),
                financing_amount: $('#FinancingAmount').val(),
                total_share: $('#TotalShareValue').val(),
                shares_data: $('#share_data').val(),
                margin_of_Financing: $('#MarginMOF').val()
            };
            //edit params
            _data.id = $('#EFA_Id').val();
            _data.tenure = $('#EFA_Tenure').val();
            _data.financing_amount = $('#EFA_FinancingAmount').val();
            _data.profit_rate = $('#EFA_ProfitRate').val();
            _data.misc_fee = $('#EFA_MiscellaneousFee').val();
            _data.cds_fee = $('#EFA_CDSFee').val();
            _data.commodity_fee = $('#EFA_CommodityFee').val();
            _data.ds_fee = $('#EFA_DigitalSigningFee').val();
            _data.mod = $('#EFA_Mod').val();
            _data.attestation_fee = $('#EFA_AttestationFee').val();
            _data.nominee_fee = $('#EFA_NomineeFee').val();
            _data.stamp_duty = $('#EFA_StampDuty').val();
            _data.other_charges = $('#EFA_OtherCharge').val();
            _data.custodion_fee = $('#EFA_CustodionFee').val();
            _data.facility_fee = $('#EFA_FacilityFee').val();
        }
        $.ajax({
            type: 'POST',
            url: '/applications/get/calculation',
            data: _data,
            success: function (result) {

                if (result) {
                    $(".hide-ipo").show();
                    $("#calculation_data").val(JSON.stringify(result));
                    $('#DFinancingAmount2').text($('#DFinancingAmount').text());
                    $('.DFinancingAmountIPO').text($('#DFinancingAmount').text());
                    if ($('.DFinancingAmountIPO').length > 0 && $('.DFinancingAmount2').text() != '') {
                        $('.DFinancingAmountIPO').text($('.DFinancingAmount2').text());
                    }
                    $('#DTenure').text(result.tenure);
                    $('.DTenure').text(result.tenure);
                    $('.DTotalProfitRate').text(result.total_profit_rate);
                    $('#DProfitRateCalc').text(result.profit_rate_calc);
                    $('#DProfitRate').text(result.profit_rate + ' per month');
                    $('#DFacilityRate').html(result.current_config.facility_duty + '% of the financing amount or <br> RM ' + (($('#FinancingAmount').val() * (parseFloat(result.current_config.facility_duty) + 1)) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' whichever is lower');
                    $('#DFacility').text(result.facility_fee);
                    $('#DCommodityFee').text(result.commodity_fee);
                    $('#DCDSFee').text(result.cds_fee);
                    $('#DDSF').text(result.digital_sign);
                    $('#DCF').text(result.custodion_fee);
                    $('#DMODF').text(result.mod);
                    $('#DAF').text(result.attestation_fee);
                    $('#DMiscFee').text(result.misc_fee);
                    $('#DNomineesFee').text(result.nominee_fee);
                    $('#DStamDutyRate').text(result.current_config.stamp_duty + ' % ad valorem');
                    $('#DStamDuty').text(result.stamp_duty);
                    $('#DOthers').text(result.other_charges);
                    $('#DTotalCharges').text(result.total_charges);
                    $('#DSalePrice').text(result.sell_price);
                    $('.DSalePrice').text(result.sell_price);

                    //bottom
                    $('#DFacility2').text(result.facility_fee);
                    $('#DCommodityFee2').text(result.commodity_fee);
                    $('#DCDSFee2').text(result.cds_fee);
                    $('#DDSF2').text(result.digital_sign);
                    $('#DCF2').text(result.custodion_fee);
                    $('#DMODF2').text(result.mod);
                    $('#DAF2').text(result.attestation_fee);
                    $('#DMiscFee2').text(result.misc_fee);
                    $('#DNomineesFee2').text(result.nominee_fee);
                    $('#DStamDutyRate2').text(result.current_config.stamp_duty + ' % ad valorem');
                    $('#DStamDuty2').text(result.stamp_duty);

                    var loc = result.outstanding_amount;
                    if (loc != '') {
                        // console.log(loc);
                        // loc = parseFloat(loc.replace(",",""));
                        // loc = loc.toFixed(2);
                        loc = loc.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                        // console.log(loc);
                        loc = parseFloat(loc.replace(",", "")).toFixed(2);
                        loc = loc.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                    }
                    $('#DOutstandingAmount').text(loc);
                    $('#DOutstandingAmount_Settlement').text(loc);
                    $('#DDisbursedAmount').text(result.disbursed_amount);
                    $('#DDisbursedAmount_2').text(result.disbursed_amount);

                    $('#DMarginRatioApproved').text(result.margin_ratio_approved + ' %');
                    $('#DMarginCallRatio').text(result.margin_call_ratio + ' %');
                    $('#DForceSellingRatio').text(result.force_selling_ratio + ' %');

                    if (isEdit) {
                        $('.DFinancingAmount').text('RM ' + result.financing_amount);
                        $('.DFinancingAmountIPO').text('RM ' + result.financing_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                    }

                    if (result.balance_subscription) {
                        $('.DBalanceSubscription').show();
                        $('#DBalanceSubscription').html('RM ' + result.balance_subscription);
                        // $('.DBalanceSubscriptionIPO').html('RM ' + result.balance_subscription);
                    } else {
                        $('.DBalanceSubscription').hide();
                    }

                    if (!isEdit) {
                        if ($('#product').val() == 'Cash Drawdown') {
                            let lastValue = $('#share_data').val();
                            let totalValue = 0;
                            if (lastValue.length > 0) {
                                let data = JSON.parse(lastValue);

                                if ($('#product').val() == 'Cash Drawdown') {
                                    data.forEach(element => {
                                        totalValue += parseFloat(element.total_share.split('RM ')[1].replace(/,/g, ''));
                                    });
                                }
                            }
                            $('#DTotalShare').text('RM ' + totalValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                            $('#DTotalShareCapped').text($('#TotalShareValue').val());
                            $('.DTotalShareCapped').show();

                        }
                        if ($('#product').val() == 'ESOS') {
                            let lastValue = $('#share_data').val();
                            let totalValue = 0;
                            if (lastValue.length > 0) {
                                let data = JSON.parse(lastValue);

                                data.forEach(element => {
                                    totalValue += parseFloat(element.total_share.split('RM ')[1].replace(/,/g, ''));
                                });
                            }
                            $('#DTotalShare').text('RM ' + totalValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

                        }
                    }

                    if (result.disbursed_amount) {
                        $('.DDisbursedAmount').show();
                    } else {
                        let disbursed_amount = $('#DTotalShare').text();
                        if ($('#DTotalShare').text().includes('RM')) {
                            disbursed_amount = $('#DTotalShare').text().split('RM ')[1];
                        }
                        $('#DDisbursedAmount').text(disbursed_amount);
                        $('#DDisbursedAmount_2').text(disbursed_amount);

                        if ($('#product').val() == 'Private Placement Financing') {
                            $('.DDisbursedAmountLabel').text('Total Consideration');
                        } else {
                            $('.DDisbursedAmountLabel').text('Subscription Amount');
                        }
                        $('.DDisbursedAmount').show();
                    }

                    if ($('#product').val() == 'ESOS') {
                        let lastValue = $('#share_data').val();
                        let totalValue = 0;
                        if (lastValue.length > 0) {
                            let data = JSON.parse(lastValue);

                            data.forEach(element => {
                                totalValue += parseFloat(element.total_share.split('RM ')[1].replace(/,/g, ''));
                            });
                        }
                        $('#DCurrentMarginRation').text(((totalValue / result.outstanding_amount) * 100).toFixed() + ' %');
                        $('.DCurrentMarginRation').show();
                        if (((totalValue / result.outstanding_amount) * 100) < result.margin_ratio_approved) {
                            $('#DCurrentMarginRation').addClass('text-danger').addClass('font-weight-bold')
                        } else {
                            $('#DCurrentMarginRation').removeClass('text-danger').removeClass('font-weight-bold')
                        }
                    } else {
                        // console.log(result);
                        $('#DCurrentMarginRation').text(((parseFloat($('#TotalShareValue').val().split('RM ')[1].replace(/,/g, '')) / result.outstanding_amount) * 100).toFixed() + ' %');
                        $('.DCurrentMarginRation').show();
                        if (((parseFloat($('#TotalShareValue').val().split('RM ')[1].replace(/,/g, '')) / result.outstanding_amount) * 100) < result.margin_ratio_approved) {
                            $('#DCurrentMarginRation').addClass('text-danger').addClass('font-weight-bold')
                        } else {
                            $('#DCurrentMarginRation').removeClass('text-danger').removeClass('font-weight-bold')
                        }
                        if (!isEdit) {
                            if ($('#outstanding_amount').length > 0) {
                                var result_1 = ((parseFloat($('#TotalShareValue').val().split('RM ')[1].replace(/,/g, '')) / $('#outstanding_amount').val().replace(/,/g, '')) * 100).toFixed() + ' %';
                                // console.log(result_1, parseFloat($('#TotalShareValue').val().split('RM ')[1].replace(/,/g, '')), $('#outstanding_amount').val().replace(/,/g, ''), );
                                if (result_1 == 'Infinity %') {
                                    result_1 = '-';
                                }
                                $('#DCurrentMarginRation').text(result_1);
                                $('.DCurrentMarginRation').show();
                                if (((parseFloat($('#TotalShareValue').val().split('RM ')[1].replace(/,/g, '')) / $('#outstanding_amount').val().replace(/,/g, '')) * 100) < result.margin_ratio_approved) {
                                    $('#DCurrentMarginRation').addClass('text-danger').addClass('font-weight-bold')
                                } else {
                                    $('#DCurrentMarginRation').removeClass('text-danger').removeClass('font-weight-bold')
                                }
                            }
                        }
                    }
                    $(".show-ipo").hide();
                    // $(".hide-ipo").hide();
                    if ($('#product').val() == 'IPO Financing') {
                        console.log('ipo');
                        let totalsharevalue = $("#TotalShareValue").val().replace(/,/g, '').replace('RM ', '');
                        let financing_amount = $("#FinancingAmount").val().replace(/,/g, '').replace('RM ', '');
                        $('.DBalanceSubscriptionIPO').html('RM ' + totalsharevalue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                        let balance_subscription = (totalsharevalue - financing_amount);
                        $(".BalanceSubscriptionDue").text(balance_subscription.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                        $(".hide-ipo").hide();
                        $(".show-ipo").show();
                    }
                    else {
                        console.log('X ipo');
                        $(".hide-ipo").show();
                    }
                }
                setInterval(function () {
                    loading.modal('hide');
                }, 500);
            },
        });
    }

    if ($('.loadCalculation').length > 0) {
        updateFinalFinancing();
    }

    $('#ApplicationListTable').DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        bLengthChange: false,
        ajax: {
            "url": "/applications/ajax",
            "type": "POST",
            "data": function (d) {
                d._token = $('meta[name="csrf-token"]').attr('content');
                d.type = $('#tableType').val();
            },
        },
        columns: [{
            data: 'DT_RowIndex',
            name: 'DT_RowIndex',
            className: "text-center align-middle",
            orderable: false,
            searchable: false,
        },
        {
            data: 'info',
            name: 'info',
            render: function (data) {
                let entities = [
                    ['amp', '&'],
                    ['apos', '\''],
                    ['#x27', '\''],
                    ['#x2F', '/'],
                    ['#39', '\''],
                    ['#47', '/'],
                    ['lt', '<'],
                    ['gt', '>'],
                    ['nbsp', ' '],
                    ['quot', '"']
                ];
                for (let i = 0, max = entities.length; i < max; ++i) {
                    data = data.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);
                }

                return data;
            },
        },
        {
            data: 'id',
            orderable: false,
            className: "text-center",
            render: function (data, type, row) {
                switch (row.status) {
                    case 3:
                        return '<a href="/applications/' + data + '/show" class="btn btn-sm d-sm-inline-block ml-1 btn-primary">View Offer</a>';
                        break;
                    case 5:
                        if (row.flag == 1) {
                            return '<a href="/applications/' + data + '/show" class="btn btn-sm d-sm-inline-block ml-1 btn-primary">Upload Shares Proof</a>';
                        }
                        else {
                            return '<a href="/applications/' + data + '/show" class="btn btn-sm d-sm-inline-block ml-1 btn-primary">Sign Agreement</a>';
                        }
                        break;
                    default:
                        return '<a href="/applications/' + data + '/show" class="btn btn-sm d-sm-inline-block ml-1 btn-primary">View Details</a>';
                }
            },
        },
        ],
        order: [
            [0, 'asc']
        ],
        pageLength: 10,
        language: {
            paginate: {
                previous: "<i class='fas fa-angle-left'></i>",
                next: "<i class='fas fa-angle-right'></i>"
            },
        },
    });

    var AdminApplicationListTable = $('#AdminApplicationListTable').DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        bLengthChange: false,
        ajax: {
            "url": "/applications/admin/ajax",
            "type": "POST",
            "data": function (d) {
                d._token = $('meta[name="csrf-token"]').attr('content');
                d.status = $("#filter-application-status").val();
                d.datestart = $("#ds").val();
                d.dateend = $("#de").val();
            },
        },
        columns: [{
            data: 'DT_RowIndex',
            name: 'DT_RowIndex',
            className: "text-center align-middle",
            orderable: false,
            searchable: false,
        },
        {
            data: 'ref_no',
            name: 'ref_no',
            className: "text-left",
            render: function (data, type, row) {
                let html = 'Reference No: ' + data + '<br>' +
                    'Product : ' + row.product;
                return html;
            },
        },
        {
            data: 'user_name',
            name: 'user_name',
            className: "text-left",
        },
        {
            data: 'financing_amount',
            name: 'financing_amount',
            className: "text-right",
            render: function (data, type, row) {
                return 'RM ' + data.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
        },
        {
            data: 'created_at',
            name: 'created_at',
            className: "text-center",
            render: function (data, type, row) {
                return moment(data).format('DD/MM/YYYY');
            }
        },
        {
            data: 'status',
            name: 'status',
            className: "text-center",
            render: function (data, type, row) {
                let badge;
                if (data == 1) {
                    badge = '<span class="badge badge-pill badge-primary">In-Process</span>';
                }
                else if (data == 2) {
                    //
                    badge = '<span class="badge badge-pill badge-primary">Pending for Approval</span>';

                } else if (data == 3) {
                    badge = '<span class="badge badge-pill badge-warning">Pending For Payment</span>';
                } else if (data == 4) {
                    badge = '<span class="badge badge-pill badge-warning">Waiting for CDS Account Process</span>';
                } else if (data == 5) {
                    if (row.flag == 1) {
                        badge = '<span class="badge badge-pill badge-primary">Waiting for Upload Proof Shares</span>';
                    }
                    else {
                        badge = '<span class="badge badge-pill badge-primary">Signature Agreement</span>';
                    }
                } else if (data == 6) {
                    badge = '<span class="badge badge-pill badge-success">Waiting for Disbursement</span>';
                } else if (data == 7) {
                    if (row.margin_call_status == '2') {
                        badge = '<span class="badge badge-pill badge-danger">**Margin Call</span>';
                    }
                    else if (row.margin_call_status == '3') {
                        badge = '<span class="badge badge-pill badge-danger">**Force Sell</span>';
                    }
                    else {
                        badge = '<span class="badge badge-pill badge-success">Monitoring</span>';
                    }
                } else if (data == 8) {
                    badge = '<span class="badge badge-pill badge-success">Completed</span>';
                } else if (data == 9) {
                    badge = '<span class="badge badge-pill badge-danger">Rejected</span>';
                } else {
                    badge = '<span class="badge badge-pill badge-dark">No Status</span>';
                }
                return badge;
            }
        },
        {
            data: 'reviewed_by_str',
            name: 'reviewed_by_str',
            className: "text-left",
            render: function (data, type, row) {
                var str = '';
                // if(data != 'N/A'){
                //     str = 'Reviewed By: ' +data + '<small>'+moment(row.reviewed_at).format('DD/MM/YYYY')+'</small>';
                // }
                if (data != 'N/A') {
                    str = 'Reviewed By: ' + data;//+ ' <small>'+moment(row.reviewed_at).format('DD/MM/YYYY')+'</small>';
                    if (row.approved_by_str != 'N/A') {
                        str = str + '<br>Approved By: ' + row.approved_by_str;//+ ' <small>'+moment(row.approved_at).format('DD/MM/YYYY')+'</small>';
                    }
                }
                else {
                    str = data;
                }
                return str;
            },
        },
        {
            data: 'application_id',
            orderable: false,
            className: "text-center",
            render: function (data, type, row) {
                var str = '';
                if (row.status == 3) {
                    // console.log(row.payment, row.totalpayment);
                    var payment = row.payment;
                    var dp = '<small>';
                    payment.forEach(function (obj) {
                        dp = dp + obj.name + ': RM' + obj.total + '<br>';
                    });
                    dp = dp + '</small>';
                    dp = dp + '<b>TOTAL: RM' + row.totalpayment + '</b>';

                    str = str + '<a href="javascript:void(0);" class="btn btn-sm d-sm-inline-block ml-1 btn-warning" data-toggle="popover" title="Payment Details" data-content="' + dp + '">Payment</a>';
                }
                // if($("#etrue").length > 0 && row.status == 2){
                //     str = str + '';
                // }
                // else{
                str = str + '<a href="/applications/' + data + '/show" class="btn btn-sm d-sm-inline-block ml-1 btn-primary">Evaluate</a>';
                // }

                return str;
            },
        },
        ],
        order: [
            [0, 'asc']
        ],
        pageLength: 10,
        language: {
            paginate: {
                previous: "<i class='fas fa-angle-left'></i>",
                next: "<i class='fas fa-angle-right'></i>"
            },
        },
        "drawCallback": function (settings) {
            $('[data-toggle="popover"]').popover({
                html: true
            });
        }
    });



    var AdminApplicationHistoryListTable = $('#AdminApplicationHistoryListTable').DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        bLengthChange: false,
        ajax: {
            "url": "/applications/admin/history/ajax",
            "type": "POST",
            "data": function (d) {
                d._token = $('meta[name="csrf-token"]').attr('content');
                d.datestart = $("#ds").val();
                d.dateend = $("#de").val();
                d.status = $("#filter-application-status").val()
            },
        },
        columns: [{
            data: 'DT_RowIndex',
            name: 'DT_RowIndex',
            className: "text-center align-middle",
            orderable: false,
            searchable: false,
        },
        {
            data: 'ref_no',
            name: 'ref_no',
            className: "text-center",
        },
        {
            data: 'user_name',
            name: 'user_name',
            className: "text-left",
        },
        {
            data: 'financing_amount',
            name: 'financing_amount',
            className: "text-right",
            render: function (data, type, row) {
                return 'RM ' + data;
            },
        },
        {
            data: 'created_at',
            name: 'created_at',
            className: "text-center",
            render: function (data, type, row) {
                return moment(data).format('DD/MM/YYYY');
            }
        },
        {
            data: 'status',
            name: 'status',
            className: "text-center",
            render: function (data, type, row) {
                let badge;
                if (data == 1 || data == 2) {
                    badge = '<span class="badge badge-pill badge-primary">In-Process</span>';
                } else if (data == 3) {
                    badge = '<span class="badge badge-pill badge-warning">Pending For Payment</span>';
                } else if (data == 4) {
                    badge = '<span class="badge badge-pill badge-warning">Waiting for CDS Account Process</span>';
                } else if (data == 5) {
                    badge = '<span class="badge badge-pill badge-primary">Signature Agreement</span>';
                } else if (data == 6) {
                    badge = '<span class="badge badge-pill badge-success">Waiting for Disbursement</span>';
                } else if (data == 7) {
                    badge = '<span class="badge badge-pill badge-success">Monitoring</span>';
                } else if (data == 8) {
                    badge = '<span class="badge badge-pill badge-success">Completed</span>';
                } else if (data == 9) {
                    badge = '<span class="badge badge-pill badge-danger">Rejected</span>';
                } else {
                    badge = '<span class="badge badge-pill badge-dark">No Status</span>';
                }
                return badge;
            }
        },
        {
            data: 'application_id',
            orderable: false,
            className: "text-center",
            render: function (data, type, row) {
                return '<a href="/applications/' + data + '/show" class="btn btn-sm d-sm-inline-block ml-1 btn-primary">View</a>';
            },
        },
        ],
        order: [
            [0, 'asc']
        ],
        pageLength: 10,
        language: {
            paginate: {
                previous: "<i class='fas fa-angle-left'></i>",
                next: "<i class='fas fa-angle-right'></i>"
            },
        },
    });

    $(document).on('change', '#filter-application-status', function () {
        if ($(this).val() == 1) {
            AdminApplicationListTable.column(5).search('1|2', true, false, true).draw();
        } else {
            // AdminApplicationListTable.column(5).search( $(this).val() ).draw();
            AdminApplicationListTable.draw();
        }
        $(".btn-monitoring-excel").css('display', "none");
        if ($(this).val() == 7 || $(this).val() == 72 || $(this).val() == 73) {
            $(".btn-monitoring-excel").css('display', "inline");
        }

        if ($("#AdminApplicationHistoryListTable").length > 0) {
            AdminApplicationHistoryListTable.ajax.reload();
        }

    });

    $(document).on('click', '.makeItRequiredBtn', function () {
        $('.makeItRequired').prop('required', $(this).data('state'));
        if ($(this).data('state')) {
            $('.reason').prop('required', false);
        } else {
            $('.reason').prop('required', true);
        }
    });

    $(document).on("click", ".payment-upfront", function () {
        // updateFinalFinancing(true);
    });
    $(document).on('click', '#btnEditFinancingAmount', function () {
        updateFinalFinancing(true);
        $('#EditFinancingAmount').modal('hide');
    });

    //
    $(document).on('click', '.btn-submit-doc', function (e) {
        e.preventDefault();
        let form = $("#DocumentSharesProof");
        bootbox.confirm({
            message: "Are sure want to upload the file?",
            buttons: {
                confirm: {
                    label: 'Upload',
                    className: 'btn-success'
                },
                cancel: {
                    label: 'Cancel',
                    className: 'btn-secondary'
                }
            },
            callback: function (result) {
                if (result) {
                    form.trigger('submit');
                }
            }
        });

    });
    $(document).on('click', '#ApproveBtn', function () {
        let DCurrentMarginRation = parseFloat($('#DCurrentMarginRation').text().replace(/%/g, ''));
        let DApprovedMarginRation = parseFloat($('#DApprovedMarginRation').text().replace(/%/g, ''));

        if (DCurrentMarginRation >= DApprovedMarginRation) {
            $('#ApproveModal').modal('toggle');
        } else {
            bootbox.confirm({
                message: "The Current Margin Ratio <b class='text-danger'>" + $('#DCurrentMarginRation').text() + "</b> <b>is lower</b> than Margin Ratio Approved. Are sure want to continue?",
                buttons: {
                    confirm: {
                        label: 'Approve Anyways',
                        className: 'btn-success'
                    },
                    cancel: {
                        label: 'Cancel',
                        className: 'btn-secondary'
                    }
                },
                callback: function (result) {
                    if (result) {
                        $('#ApproveModal').modal('toggle');
                    }
                }
            });
        }
    });

    if ($('.js-data-user').length > 0) {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $('.js-data-user').on('select2:select', function (e) {
            var form = $(document.createElement('form'));
            $(form).attr("action", "/applications/createmanual");
            $(form).attr("method", "POST");
            var input = $("<input>")
                .attr("type", "hidden")
                .attr("name", "_token")
                .val($('meta[name="csrf-token"]').attr('content'));
            $(form).append($(input));
            var input = $("<input>")
                .attr("type", "hidden")
                .attr("name", "id")
                .val($(this).val());
            $(form).append($(input));
            form.appendTo(document.body)
            $(form).submit();
        });
        $('.js-data-user').select2({
            ajax: {
                url: '/users/userAjax',
                dataType: 'json',
                method: 'post',
                delay: 250,
                data: function (params) {
                    return {
                        q: params.term
                    };
                },
                processResults: function (data) {
                    return {
                        results: $.map(data, function (item) {
                            if (item.nric !== null) {
                                return {
                                    text: item.name + ' (' + item.nric + ')',
                                    id: item.id
                                }
                            }
                            else {
                                return {
                                    text: item.name,
                                    id: item.id
                                }
                            }

                        })
                    };
                },
                cache: true
            }
        });
    }

    $(document).on('input', '.cds_acc_no_masking', function () {
        // $(this).mask('000000-0000-0000');
        $(this).mask('000-000-000000000');
    });

    //document_type
    $('#DigitalSignModal').on('shown.bs.modal', function (e) {
        let documentType = $(e.relatedTarget).data('documenttype');
        let url = $(e.relatedTarget).data('url');
        let nric = $(e.relatedTarget).data('nric');
        let info = $(e.relatedTarget).data('info');
        let position = $(e.relatedTarget).data('position');

        $("#document_type").val("").val(documentType);
        $("#position").val("").val(position);
        $("#nric").val("").val(nric);
        $("#cert-info").hide();
        $.post(url,
            {
                _token: $('meta[name="csrf-token"]').attr('content'),
                nric: nric,
            },
            function (data) {
                if (data === '1') {
                    $(".signature-check").prop("disabled", false).removeClass('btn-dark').addClass('btn-success');
                    $("#cert-info").removeClass('badge-danger').addClass('badge-success').html('<i class="fa fa-solid fa-check"></i> ' + info).show();
                    $("#loading-cert-text").text("Sign Now");
                }
                else {
                    $(".signature-check").prop("disabled", true).removeClass('btn-success').addClass('btn-dark');
                    $("#cert-info").removeClass('badge-success').addClass('badge-danger').html('<i class="fa fa-solid fa-times"></i> ' + info).show();
                    $("#loading-cert-text").text("MyTrustID Account Not Found");
                }
            });
    });
    $('#DigitalSignModal').on('hidden.bs.modal', function (e) {
        $("#document_type").val('');
    });

    if ($("#js-btn-submit-topup").length > 0) {
        $("#js-btn-submit-topup").on("click", function (e) {
            e.preventDefault();
            let checked = 0;
            $("input[name='margin_call_options[]']").each(function (e) {
                if ($(this).prop("checked")) {
                    checked++;
                }
            })
            if (checked > 0) {
                bootbox.confirm({
                    message: "Are sure want to proceed with the selected top up action?",
                    buttons: {
                        confirm: {
                            label: 'Yes, Proceed',
                            className: 'btn-success'
                        },
                        cancel: {
                            label: 'Cancel',
                            className: 'btn-secondary'
                        }
                    },
                    callback: function (result) {
                        $("#margin-call-action").submit();
                    }
                });
            }
            else {
                bootbox.alert({
                    centerVertical: true,
                    message: "Please select top up action at least one (1).",
                });
            }
        });
    }
    if ($("#mc_cash").length > 0) {
        $("#mc_cash").on("change", function () {

        });
    }
    if ($("#btn-edit-save-app").length > 0) {
        $("#btn-edit-save-app").on("click", function (e) {
            e.preventDefault();
            // console.log('ssss');
            if ($("#mc_cash").length > 0 && $("#mc_cash").val() != '') {
                // console.log('cash', $("#mc_cash").val())
                let checkEditMargin = parseFloat($("#checkEditMargin").val());
                let mccash = parseFloat($("#mc_cash").val());
                if (mccash < checkEditMargin) {
                    bootbox.alert({
                        centerVertical: true,
                        message: "Please enter top up amount >= RM" + checkEditMargin,
                    });
                    return;
                }
                bootbox.confirm({
                    message: "Please click <b>ACCEPT</b> only after the Top up amount payment has been made by the applicant.",
                    buttons: {
                        confirm: {
                            label: 'Accept',
                            className: 'btn-success'
                        },
                        cancel: {
                            label: 'Cancel',
                            className: 'btn-secondary'
                        }
                    },
                    callback: function (result) {
                        // console.log('rrr',result);
                        if (result) {
                            $.post("/applications/margin/marginupdate",
                                {
                                    _token: $('meta[name="csrf-token"]').attr('content'),
                                    application_id: $("#application_id").val(),
                                    cash: $("#mc_cash").val()
                                },
                                function (data, status) {
                                    if (data.status == 'true') {
                                        window.location.href = '/applications/' + $("#application_id_key").val() + '/show';
                                    }
                                }, 'json');
                        }
                    }
                });
            }

            if ($("#total_share_value").length > 0 && $("#total_share_value").val() != '') {
                // console.log('total_share_value', $("#total_share_value").val())
                bootbox.confirm({
                    message: "Please click <b>ACCEPT</b> only after the Top up share value agreed by the applicant.",
                    buttons: {
                        confirm: {
                            label: 'Accept',
                            className: 'btn-success'
                        },
                        cancel: {
                            label: 'Cancel',
                            className: 'btn-secondary'
                        }
                    },
                    callback: function (result) {
                        // console.log('rrr',result);
                        if (result) {
                            $.post("/applications/margin/marginupdate",
                                {
                                    _token: $('meta[name="csrf-token"]').attr('content'),
                                    application_id: $("#application_id").val(),
                                    total_share_value: $("#total_share_value").val(),
                                    share_data: $("#share_data").val()
                                },
                                function (data, status) {
                                    if (data.status == 'true') {
                                        window.location.href = '/applications/' + $("#application_id_key").val() + '/show';
                                    }
                                }, 'json');
                        }
                    }
                });
            }

            if ($(".mc_sell_share").length > 0) {
                // console.log('cash', $("#mc_cash").val())
                bootbox.confirm({
                    message: "Please click <b>ACCEPT</b> only after the Sell shares value agreed by the applicant.",
                    buttons: {
                        confirm: {
                            label: 'Accept',
                            className: 'btn-success'
                        },
                        cancel: {
                            label: 'Cancel',
                            className: 'btn-secondary'
                        }
                    },
                    callback: function (result) {
                        // console.log('rrr',result);
                        if (result) {
                            $("#mc_sell_form").submit();
                        }
                    }
                });
            }


            ///
        });
    }

    // console.log('cash',$('#product').val());

    if ($('#product').val() == 'Cash Drawdown') {
        $('.cdd-table').parents('div.dataTables_wrapper').first().show();
        $('.ipo-table').parents('div.dataTables_wrapper').first().hide();
        $('.esos-table').parents('div.dataTables_wrapper').first().hide();
        $('.pp-tables').parents('div.dataTables_wrapper').first().hide();
        $('.DTotalShareCapped').show();
        $('.UserShareAlloted').hide();
        $('.totalValueText').html('Total Capped Share Value<span class="text-danger">*</span>');
        $('.cddNote').show();
        $('.ListCompanyLastLabel').text('Market Price');
        $('.UserShareValueLabel').text('No. of Shares Pledged');
        $('.CalcTotalShareValueLabel').text('Total Share Value');
        $('#UserShareValue').attr('placeholder', 'No. of Shares Pledged');
        $('#CalcTotalShareValue').attr('placeholder', 'Share Values ');

    } else if ($('#product').val() == 'ESOS') {
        $('.cdd-table').parents('div.dataTables_wrapper').first().hide();
        $('.ipo-table').parents('div.dataTables_wrapper').first().hide();
        $('.pp-tables').parents('div.dataTables_wrapper').first().hide();
        $('.esos-table').parents('div.dataTables_wrapper').first().show();
        $('.DTotalShareCapped').hide();
        $('.UserShareValue').hide();
        $('.esos-filled').show();
        $('.esos-filled').addClass('checkModalInputs');
        $('#UserShareValue').removeClass('checkModalInputs');
        $('.totalValueText').html('Total Subscriptions Value<span class="text-danger">*</span>');

    } else {
        $('.cdd-table').parents('div.dataTables_wrapper').first().hide();
        $('.ipo-table').parents('div.dataTables_wrapper').first().show();
        $('.esos-table').parents('div.dataTables_wrapper').first().hide();
        $('.pp-tables').parents('div.dataTables_wrapper').first().hide();
        $('.UserShareAlloted').show();
        $('.DTotalShareCapped').hide();
        $('.totalValueText').html('Total Shares Value<span class="text-danger">*</span>');


        $('.ListCompanyLastLabel').text('IPO Price');
        $('.UserShareValueLabel').text('No. of Shares Subscribed');
        $('.CalcTotalShareValueLabel').text('Subscription Amount ');

        $('#UserShareValue').attr('placeholder', 'No. of Shares Subscribed');
        $('#CalcTotalShareValue').attr('placeholder', 'Subscription Amount ');

        if ($('#product').val() == 'IPO Financing') {
            $('.ListCompanyNameLabel').text('Name of Company (Stock)');
            $('.ListCompanyCode').hide();
        } else {
            $('.ListCompanyNameLabel').text('Name of Listed Company (Stock)');
            $('.ListCompanyCode').show();
        }

        if ($('#product').val() == 'Private Placement Financing') {

            $('.pp-tables').parents('div.dataTables_wrapper').first().show();
            $('.cdd-table').parents('div.dataTables_wrapper').first().hide();
            $('.ipo-table').parents('div.dataTables_wrapper').first().hide();
            $('.esos-table').parents('div.dataTables_wrapper').first().hide();

            $('.totalValueText').html('Total Share Consideration<span class="text-danger">*</span>');

            $('#UserShareAlloted').prop('readonly', true);
            let inputs = '<label for="example-text-input" class="form-control-label">Placement Price (RM)</label> <input class="form-control form-control-sm" type="number" step="0.01" placeholder="Placement Price" id="ListCompanyLast"> <input type="hidden" id="ListCompanyLast2">';
            $('.ListCompanyLast').remove();
            $('.pp-filled').html(inputs);
            $('.pp-filled').show();

            $('.UserShareAllotedLabel').text('No. of Shares Alloted/Offered');
            $('.UserShareValueLabel').text('No. of Shares Subscribed');
            $('.CalcTotalShareValueLabel').text('Total Consideration');

            $('#UserShareAlloted').attr('placeholder', 'No. of Shares Alloted/Offered');
            $('#UserShareValue').attr('placeholder', 'No. of Shares Subscribed');
            $('#CalcTotalShareValue').attr('placeholder', 'Total Consideration');
        }
    }
    function number_format(number, decimals, dec_point, thousands_sep) {
        number = number * 1;//makes sure `number` is numeric value
        var str = number.toFixed(decimals ? decimals : 0).toString().split('.');
        var parts = [];
        for (var i = str[0].length; i > 0; i -= 3) {
            parts.unshift(str[0].substring(Math.max(0, i - 3), i));
        }
        str[0] = parts.join(thousands_sep ? thousands_sep : ',');
        return str.join(dec_point ? dec_point : '.');
    }
    if ($(".mc_sell_share").length > 0) {
        // console.log('load');
        let checkEditMargin = $("#checkEditMargin").val();
        $(".mc_sell_share").on("change keyup", function () {
            let code = $(this).data("id");
            let val = $(this).val();
            let txt = $(".txt_mc_sell_share_" + code);
            let capped = $(".capped_" + code).val();
            let capped_share_value = $(".capped_share_value_" + code);
            let last_price = $(".last_price_" + code).val();

            let calc = (val * last_price) * (capped / 100);
            txt.text('RM' + number_format(calc, 2));
            capped_share_value.val(calc);

            if (calc >= parseFloat(checkEditMargin.replace(/,/g, ''))) {
                // $("#btn-edit-save-app").prop("disabled",false);
            }
            else {
                // $("#btn-edit-save-app").prop("disabled",true);

            }
            // console.log(), , last_price);
            let total = 0;
            // let checkEditMargin = $("#checkEditMargin").val();
            $(".csv").each(function (e) {
                let v = $(this).val();
                total = parseFloat(total) + parseFloat(v.replace(/,/g, ''));
                total = total.toFixed(2);
                // console.log(parseFloat(v),'total: '+total);
            });
            // console.log('total: '+total + ' => '+checkEditMargin);
            if (total >= parseFloat(checkEditMargin.replace(/,/g, ''))) {
                // $("#btn-edit-save-app").prop("disabled",false);
            }
            else {
                // $("#btn-edit-save-app").prop("disabled",true);
            }
        });
        //mc_sell_share txt_mc_sell_share capped_share_value
    }
    if ($(".csv").length > 0) {
        let total = 0;
        let checkEditMargin = $("#checkEditMargin").val();
        $(".csv").each(function (e) {
            let v = $(this).val();
            total = parseFloat(total) + parseFloat(v.replace(/,/g, ''));
            total = total.toFixed(2);
            // console.log(parseFloat(v),'total: '+total);
        });
        // console.log(total);
        if (total >= parseFloat(checkEditMargin.replace(/,/g, ''))) {
            // $("#btn-edit-save-app").prop("disabled",false);
        }
        else {
            // $("#btn-edit-save-app").prop("disabled",true);
        }
    }

    if ($(".btn-check-queue").length > 0) {
        data = [];
        $(".btn-check-queue").each(function () {
            data.push($(this).data("id"));
        });
        const myInterval = setInterval(function () {
            $.ajax({
                type: 'POST',
                url: '/applications/signnow-ajax',
                data: {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    params: data,
                },
                success: function (result) {
                    let reload = 0;
                    let reload2 = 0;
                    for (var i = 0; i < result.length; i++) {
                        let id = result[i].id;
                        let status = result[i].status;
                        if (status != null) {
                            if (status == 'Sign Now') {
                                reload++;
                            }
                            else {
                                reload2++;
                                $("." + id).removeClass('btn-danger').removeClass('btn-warning').addClass('btn-success').html('<i class="fas fa-signature"></i>&nbsp;Approved at ' + status).prop('disabled', true);
                            }
                        }
                    }
                    if (reload > 0) {
                        window.location.reload();
                    }
                    if (reload2 == result.length) {
                        window.location.reload();
                    }
                },
            });
            // console.log('it works' + new Date());
        }, 2000);
        if (data.length == 0) {
            clearInterval(myInterval);
        }
    }

    if ($(".form-check-payment").length > 0) {
        if ($(".form-check-payment").val() == 2) {
            // $("#payment_type_settlement").show();
            // $("#payment_remark_settlement").show();
            $(".txt-payment-amount").prop('readonly', false).attr("placeholder", "Enter any amount.");
        }
        else if ($(".form-check-payment").val() == 1) {
            let outstanding = parseFloat($("#DOutstandingAmount_Settlement").text().replaceAll(",", ""));
            let closeAcc = parseFloat($("#close_acc_charge").val());
            let payment = outstanding + closeAcc;
            $(".txt-payment-amount").val(payment.toFixed(2)).prop('readonly', true);
        }
        else {
            $(".txt-payment-amount").prop('readonly', true).attr("placeholder", "Please select payment type.");
            // $("#payment_type_settlement").hide();
            // $("#payment_remark_settlement").hide();
        }
        $(".form-check-payment").on("change", function (e) {
            e.preventDefault();
            let val = $(this).val();
            $(".txt-payment-amount").val("").prop('readonly', true).attr("placeholder", "Enter any amount.");
            // console.log(val);
            let outstanding = parseFloat($("#DOutstandingAmount_Settlement").text().replaceAll(",", ""));
            let closeAcc = parseFloat($("#close_acc_charge").val());
            //payment_type_settlement_
            if (val == '2') {
                $(".txt-payment-amount").val("").prop('readonly', false).attr("placeholder", "Enter any amount.");
                $("#full-settlement-txt").css("display", "none");
                // $("#payment_type_settlement").show();
                // $("#payment_remark_settlement").show();

                // $("#payment_type_settlement_").show();
                // $("#payment_remark_settlement_").show();
            }
            else if (val == '1') {
                let payment = outstanding + closeAcc;
                $(".txt-payment-amount").val(payment.toFixed(2)).prop('readonly', true);
                $("#full-settlement-txt").css("display", "contents");
                // $("#payment_type_settlement").hide();
                // $("#payment_remark_settlement").hide();

                // $("#payment_type_settlement_").hide();
                // $("#payment_remark_settlement_").hide();
            }
            else {
                $(".txt-payment-amount").val("").prop('readonly', true).attr("placeholder", "Please select payment type.");
            }
        });
    }
    function onlyNumberKey(evt) {
        var ASCIICode = (evt.which) ? evt.which : evt.keyCode
        // console.log('ddd',ASCIICode);
        var allowed = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 190, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110];
        if (!allowed.includes(ASCIICode))
            return false;
        return true;
        // if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
        //     return false;
        // return true;
    }
    if ($(".txt-payment-amount").length > 0) {
        $(".txt-payment-amount").on("keyup", function (e) {
            // console.log('test123');
            if (!onlyNumberKey(e)) {
                if (e.keyCode != 190)
                    $(this).val($(".txt-payment-amount").val().slice(0, -1));
            }

            let outstanding = parseFloat($("#DOutstandingAmount_Settlement").text().replaceAll(",", ""));
            if ($(this).val().replaceAll(",", "") > outstanding) {
                $(this).val(outstanding);
            }
            if ($(this).val().replaceAll(",", "") == outstanding) {
                let closeAcc = parseFloat($("#close_acc_charge").val());
                $(this).val(outstanding + closeAcc);
            }
        })
    }
    if ($(".btn-submit-payment").length > 0) {
        $(".btn-submit-payment").on("click", function (e) {
            e.preventDefault();
            $("#payment-form-submit-1").trigger('submit');
        });
    }
    if ($(".btn-payment-accept-review").length > 0) {
        $(".btn-payment-accept-review").on("click", function (e) {
            e.preventDefault();
            $("#payment-form-submit-1").trigger('submit');
        });
    }

    $('#SettlementApply').on('shown.bs.modal', function () {
        let outstanding = parseFloat($("#DOutstandingAmount_Settlement").text().replaceAll(",", ""));
        let inputval = parseFloat($(".txt-payment-amount").val().replaceAll(",", ""));
        let closeAcc = parseFloat($("#close_acc_charge").val().replaceAll(",", ""));
        if (inputval != NaN) {
            $(".btn-submit-payment").prop("disabled", false);
        }
        else {
            $(".btn-submit-payment").prop("disabled", true);
        }
        let htmlStr = "<ul>";
        if (inputval > (outstanding + closeAcc)) {
            $(".btn-submit-payment").prop("disabled", true);
            // $("#txt-payment-accept-01").html("Please enter below outstanding amount.");
            $(".txt-payment-accept-02").text("Entered payment amount above from outstanding amount.");

            htmlStr += "<li>Please enter below outstanding amount.</li>";
        }
        else {
            $(".btn-submit-payment").prop("disabled", false);
            // $("#txt-payment-accept-01").html("Please note that the outstanding balance and statement of account will be applicable once our staff has verified the transaction.");
            $(".txt-payment-accept-02").text("Are you sure to proceed the payment?");

            htmlStr += "<li>Please note that the outstanding balance and statement of account will be applicable once our staff has verified the transaction.</li>";
        }
        if ($(".form-check-payment").val() === "") {
            $(".btn-submit-payment").prop("disabled", true);
            $(".txt-payment-accept-02").text("Payment type is required.");
            htmlStr += "<li>Select payment type.</li>";
        }
        if ($(".txt-payment-amount").val() === "") {
            $(".btn-submit-payment").prop("disabled", true);
            $(".txt-payment-accept-02").text("Payment amount is required.");
            htmlStr += "<li>Enter payment amount.</li>";
        }
        if ($("#payment_slip").val() === "") {
            $(".btn-submit-payment").prop("disabled", true);
            // $("#txt-payment-accept-01").html("Please upload payment slip.");


            htmlStr += "<li>Upload payment slip required.</li>";
        }
        htmlStr += "</ul>";
        $(".txt-payment-accept-02").text("Payment Confirmation");
        $("#txt-payment-accept-01").html(htmlStr);
    })


    var start = moment().subtract(29, 'days');
    var end = moment();

    function cb(start, end) {
        $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
        $("#ds").val(start.format('YYYY-MM-DD'));
        $("#de").val(end.format('YYYY-MM-DD'));
        if ($('#AdminApplicationHistoryListTable').length > 0) {
            AdminApplicationHistoryListTable.ajax.reload();
        }
        if ($('#AdminApplicationListTable').length > 0) {
            AdminApplicationListTable.ajax.reload();
        }
        // console.log(start.format('YYYY-MM-DD'),end.format('YYYY-MM-DD'));
    }

    $('#reportrange').daterangepicker({
        startDate: start,
        endDate: end,
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
    }, cb);

    cb(start, end);

    $('#reportrange').on('apply.daterangepicker', function (ev, picker) {
        // console.log(picker.startDate.format('YYYY-MM-DD'));
        // console.log(picker.endDate.format('YYYY-MM-DD'));
        if ($("#ds").length > 0) {
            $("#ds").val(picker.startDate.format('YYYY-MM-DD'));
        }
        if ($("#de").length > 0) {
            $("#de").val(picker.endDate.format('YYYY-MM-DD'));
        }
        if ($('#AdminApplicationHistoryListTable').length > 0) {
            AdminApplicationHistoryListTable.ajax.reload();
        }
        if ($('#AdminApplicationListTable').length > 0) {
            AdminApplicationListTable.ajax.reload();
        }

    });
});
