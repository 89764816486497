require('./bootstrap');
var Chart = require('chart.js')
require('datatables.net-bs4');
window.bootbox = require('bootbox');
window.moment = require('moment');
window.Select2 = require('select2');
window.toast = require('jquery-toast-plugin');
require('daterangepicker');
require('./jquery.mask');
require('./jquery.maskMoney');

require('./jquery-multi-select');

require('./applications');
require('./rollover');
require('./statements');
require('./global');

require('./counters/counter');
require('./counters/custom_counter');

require('./settings/profile');
require('./settings/users/internal');
require('./settings/users/public');
require('./settings/products');
require('./settings/cds');
require('./payment');

