$(document).ready(function () {
    $('#InternalUsersTable').DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        ajax: {
            "url": "/settings/users/internal/table/ajax",
            "type": "POST",
            "data": function (d) {
                d._token = $('meta[name="csrf-token"]').attr('content')
            },
        },
        columns: [
            {
                data: 'DT_RowIndex',
                name: 'DT_RowIndex',
                orderable: false,
                searchable: false,
            },
            {
                data: 'name',
                name: 'name',
                className: "text-left",
                render: function (data) {
                    return data.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                }
            },
            {
                data: 'nric_new',
                name: 'nric_new',
                className: "text-center",
            },
            {
                data: 'email',
                name: 'email',
                className: "text-left",
            },
            {
                data: 'user_role',
                name: 'user_role',
                className: "text-center",
                render: function (data) {
                    return data.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                }
            },
            {
                data: 'status',
                name: 'status',
                className: "text-center",
                render: function (data) {
                    if (data != null) {
                        return (data == 1) ? 'Active' : 'Blocked';
                    } else {
                        return '';
                    }
                },
            },
            {
                data: "user_id",
                orderable: false,
                className: "text-center",
                render: function (data) {
                    if (data != null) {
                        let edit    = '<a href="/settings/users/'+data+'/edit" class="btn btn-sm d-sm-inline-block btn-primary m-1" title="Edit"><i class="fas fa-edit"></i></a>';
                        let destroy = '<button data-id="'+data+'" class="btn btn-sm d-sm-inline-block btn-danger m-1 settingUserDelete" title="Delete"><i class="fas fa-trash"></i></button>';
                        return edit + ' ' + destroy;
                    } else {
                        return '';
                    }
                },
            },
        ],
        order: [
            [1, 'desc']
        ],
        pageLength: 25,
        language: {
            paginate: {
                previous: "<i class='fas fa-angle-left'></i>",
                next: "<i class='fas fa-angle-right'></i>"
            }
        }
    });

    $(document).on('click','.settingUserDelete', function(){
        let id = $(this).data("id");
        $('#SettingUsersDeleteForm').attr('action', "/settings/users/"+id);
        $('#SettingUsersDeleteModal').modal('toggle');
    });
});
