$(document).ready(function () {

    $('#RolloverListTable').DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        bLengthChange: false,
        ajax: {
            "url": "/rollover/ajax",
            "type": "POST",
            "data": function (d) {
                d._token = $('meta[name="csrf-token"]').attr('content');
            },
        },
        columns: [{
            data: 'DT_RowIndex',
            name: 'DT_RowIndex',
            className: "text-center align-middle",
            orderable: false,
            searchable: false,
        },
        {
            data: 'info',
            name: 'info',
            render: function (data) {
                let entities = [
                    ['amp', '&'],
                    ['apos', '\''],
                    ['#x27', '\''],
                    ['#x2F', '/'],
                    ['#39', '\''],
                    ['#47', '/'],
                    ['lt', '<'],
                    ['gt', '>'],
                    ['nbsp', ' '],
                    ['quot', '"']
                ];
                for (let i = 0, max = entities.length; i < max; ++i) {
                    data = data.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);
                }

                return data;
            },
        },
        {
            data: 'id',
            orderable: false,
            className: "text-center",
            render: function (data, type, row) {
                switch (row.status) {
                    case 3:
                        return '<a href="/applications/' + data + '/show" class="btn btn-sm d-sm-inline-block ml-1 btn-primary">View Offer</a>';
                    case 5:
                        return '<a href="/applications/' + data + '/show" class="btn btn-sm d-sm-inline-block ml-1 btn-primary">Sign Agreement</a>';
                    default:
                        return '<a href="/applications/' + data + '/show" class="btn btn-sm d-sm-inline-block ml-1 btn-primary">View Details</a>';
                }
            },
        },
        ],
        order: [
            [0, 'asc']
        ],
        pageLength: 10,
        language: {
            paginate: {
                previous: "<i class='fas fa-angle-left'></i>",
                next: "<i class='fas fa-angle-right'></i>"
            },
        },
    });

    var AdminRolloverListTable = $('#AdminRolloverListTable').DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        bLengthChange: false,
        ajax: {
            "url": "/rollover/admin/ajax",
            "type": "POST",
            "data": function (d) {
                d._token = $('meta[name="csrf-token"]').attr('content');
                d.status = $("#filter-application-status").val();
            },
        },
        columns: [{
            data: 'DT_RowIndex',
            name: 'DT_RowIndex',
            className: "text-center align-middle",
            orderable: false,
            searchable: false,
        },
        {
            data: 'ref_no',
            name: 'ref_no',
            className: "text-left",
            render: function (data, type, row) {
                let html = 'Reference No: ' + data + '<br>' +
                    'Product : ' + row.product;
                return html;
            },
        },
        {
            data: 'user_name',
            name: 'user_name',
            className: "text-left",
        },
        {
            data: 'financing_amount',
            name: 'financing_amount',
            className: "text-right",
            render: function (data, type, row) {
                return 'RM ' + data.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
        },
        {
            data: 'rollover_at',
            name: 'rollover_at',
            className: "text-center",
            render: function (data, type, row) {
                return data ? moment(data).format('DD/MM/YYYY') : 'N/A';
            }
        },
        {
            data: 'rollover_status',
            name: 'rollover_status',
            className: "text-center",
            render: function (data, type, row) {
                let badge;
                if (data == 1) {
                    badge = '<span class="badge badge-pill badge-primary">Rollover Pending</span>';
                } else if (data == 2) {
                    badge = '<span class="badge badge-pill badge-primary">Rollover Rejected</span>';
                } else if (data == 3) {
                    badge = '<span class="badge badge-pill badge-success">Rollover Approved</span>';
                } else {
                    badge = '<span class="badge badge-pill badge-dark">Unknown</span>';
                }
                return badge;
            }
        },
        {
            data: 'application_id',
            orderable: false,
            className: "text-center",
            render: function (data, type, row) {
                return '<a href="/applications/' + data + '/show" class="btn btn-sm d-sm-inline-block ml-1 btn-primary">Evaluate</a>';
            },
        },
        ],
        order: [
            [0, 'asc']
        ],
        pageLength: 10,
        language: {
            paginate: {
                previous: "<i class='fas fa-angle-left'></i>",
                next: "<i class='fas fa-angle-right'></i>"
            },
        },
    });

    $(document).on('change', '#filter-application-status', function () {
        AdminRolloverListTable.ajax.reload(null, true);
    });

});
