$(document).ready(function () {
    $('#PublicUsersTable').DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        ajax: {
            "url": "/settings/users/public/table/ajax",
            "type": "POST",
            "data": function (d) {
                d._token = $('meta[name="csrf-token"]').attr('content')
            },
        },
        columns: [
            {
                data: 'DT_RowIndex',
                name: 'DT_RowIndex',
                orderable: false,
                searchable: false,
            },
            {
                data: 'name',
                name: 'name',
                className: "text-left",
                render: function (data) {
                    return data.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                }
            },
            {
                data: 'nric_new',
                name: 'nric_new',
                className: "text-center",
            },
            {
                data: 'email',
                name: 'email',
                className: "text-center",
            },
            {
                data: 'user_role',
                name: 'user_role',
                className: "text-center",
                render: function (data) {
                    return data.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                }
            },
            {
                data: 'status',
                name: 'status',
                className: "text-center",
            },
            {
                data: "user_id",
                orderable: false,
                className: "text-center",
                render: function (data) {
                    if (data != null) {
                        let edit    = '<a href="/settings/users/'+data+'/edit" class="btn btn-sm d-sm-inline-block btn-primary m-1" title="Edit"><i class="fas fa-edit"></i></a>';
                        let destroy = '<button data-id="'+data+'" class="btn btn-sm d-sm-inline-block btn-danger m-1 settingUserDelete" title="Delete"><i class="fas fa-trash"></i></button>';
                        return edit + ' ' + destroy;
                    } else {
                        return '';
                    }
                },
            },
        ],
        order: [
            [1, 'desc']
        ],
        pageLength: 25,
        language: {
            paginate: {
                previous: "<i class='fas fa-angle-left'></i>",
                next: "<i class='fas fa-angle-right'></i>"
            }
        }
    });

    if ($('.create_user_form').length > 0) {
        $.ajax({
            type: 'PUT',
            url: '/settings/users/create/public_template_individu',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
            },
            success: function (result) {
                $('.create_user_form').html(result);
            },
        });
    }


    $(document).on('change','.create_user_user_role', function(){
        let role = $(this).val();
        if (role == 'individual') {
            // $('.create_user_corporate').hide();
            // $('.create_user_individual').show();
            $.ajax({
                type: 'PUT',
                url: '/settings/users/create/public_template_individu',
                data: {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                },
                success: function (result) {
                    $('.create_user_form').html(result);
                },
            });

        } else if (role == 'corporate'){
            // $('.create_user_corporate').show();
            // $('.create_user_individual').hide();
            $.ajax({
                type: 'PUT',
                url: '/settings/users/create/public_template_corporate',
                data: {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                },
                success: function (result) {
                    $('.create_user_form').html(result);
                },
            });

        }
    });
});
