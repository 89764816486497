const moment = require("moment");

$(document).ready(function () {

    $(document).on('click', '#add_board_of_directors', function () {

        let count = $('#add_board_of_directors').val() + 1;
        let html = '<div class="row" id="board_of_directors_group'+count+'">'+

                        '<div class="col">' +
                            '<div class="row">' +

                                '<div class="col-lg-4">'+
                                    '<div class="form-group">'+
                                        '<label class="form-control-label">Name</label>'+
                                        '<input type="text"  name="director_name[]" class="form-control form-control-alternative " placeholder="Name" required>'+
                                    '</div>'+
                                '</div>'+

                                '<div class="col-lg-4">'+
                                    '<div class="form-group">'+
                                        '<label class="form-control-label">NRIC No</label>'+
                                        '<input type="text"  name="director_nric[]" class="form-control form-control-alternative nric_validator" data-pass="'+count+'" placeholder="NRIC Number" maxlength="12" onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))" required>'+
                                        '<span class="text-danger" role="alert" id="nric_invalid'+count+'"></span>'+
                                    '</div>'+
                                '</div>'+

                                '<div class="col-lg-4">'+
                                    '<div class="form-group">'+
                                        '<label class="form-control-label">Tel No</label>'+
                                        '<input type="number"  name="director_tel_no[]" class="form-control form-control-alternative " placeholder="01X-XXXXXXX" required>'+
                                    '</div>'+
                                '</div>'+

                                '<div class="col-lg-12">' +
                                    '<div class="form-group">' +
                                        '<label class="form-control-label">Address</label>' +
                                        '<textarea name="director_address[]" class="form-control form-control-alternative" placeholder="Address" rows="4"></textarea>' +
                                    '</div>' +
                                '</div>' +

                            '</div>' +
                        '</div>' +

                        '<div class="col-auto pl-0">' +
                            '<div class="col-auto p-0">' +
                                '<div class="form-group">'+
                                    '<label class="form-control-label">&nbsp;</label>'+
                                    '<button type="button" class="btn btn-sm btn-danger form-control destroy_board_of_directors" data-id="'+count+'"><i class="fas fa-trash-alt"></i></button>'+
                                '</div>'+
                            '</div>'+
                        '</div>'+

                    '</div>';
        $('#board_of_directors_div').append(html);
        $('#add_board_of_directors').val(count);
    });


    $(document).on('click', '.destroy_board_of_directors', function () {
        let id = $(this).data('id');
        $('#board_of_directors_group'+id).remove();
    });

    $(document).on('click', '#address_same', function () {
        if ($('#address_same').is(":checked")) {
            $('#mailing_address').val($('#permanent_address').val());
            $('#mailing_postcode').val($('#permanent_postcode').val());
            let html = '<option value="" disabled>Choose States First</option><option selected value="' + $('#permanent_city').val() + '">' + $('#permanent_city').val() + '</option>';
            $('#mailing_city').html(html);
            html = '<option value="" disabled>Choose Country First</option><option selected value="' + $('#permanent_state').val() + '">' + $('#permanent_state').val() + '</option>';
            $('#mailing_state').html(html);
            $('#mailing_city').val($('#permanent_city').val());
            $('#mailing_state').val($('#permanent_state').val());
            $('#mailing_country').val($('#permanent_country').val());

            $('#registered_address').val($('#business_address').val());
            $('#registered_postcode').val($('#business_postcode').val());
            let html2 = '<option value="" disabled>Choose States First</option><option selected value="' + $('#business_city').val() + '">' + $('#business_city').val() + '</option>';
            $('#registered_city').html(html2);
            html2 = '<option value="" disabled>Choose Country First</option><option selected value="' + $('#business_state').val() + '">' + $('#business_state').val() + '</option>';
            $('#registered_state').html(html2);
            $('#registered_city').val( $('#business_city').val() );
            $('#registered_state').val( $('#business_state').val() );
            $('#registered_country').val( $('#business_country').val() );
        } else {
            $('#mailing_address').val('');
            $('#mailing_postcode').val('');
            $('#mailing_city').html('<option value="" selected disabled>Choose States First</option>');
            $('#mailing_state').html('<option value="" selected disabled>Choose Country First</option>');
            $('#mailing_country').val('');

            $('#registered_address').val('');
            $('#registered_postcode').val('');
            $('#registered_city').html('<option value="" selected disabled>Choose States First</option>');
            $('#registered_state').html('<option value="" selected disabled>Choose Country First</option>');
            $('#registered_country').val('');
        }
    });

    $(document).on('input', '#mailing_address, #mailing_postcode, #mailing_city, #mailing_state, #mailing_country', function () {
        let ids_mailing = [
            '#mailing_address',
            '#mailing_postcode',
            '#mailing_city',
            '#mailing_state',
            '#mailing_country',
        ];
        let status = true;
        ids_mailing.forEach(element => {
            if (status) {
                let id_field = element.split('_')[1];
                if($('#permanent_' + id_field).val() != $(element).val()){
                    status = false;
                    console.log('false');
                    $('#address_same').prop('checked', status);
                    return false;
                } else {
                    console.log('true');
                    status = true;
                }
            }
        });
        $('#address_same').prop('checked', status);
    });


    $(document).on('input', '#registered_address, #registered_postcode, #registered_city, #registered_state, #registered_country', function () {
        let ids_mailing = [
            '#registered_address',
            '#registered_postcode',
            '#registered_city',
            '#registered_state',
            '#registered_country',
        ];
        let status = true;
        ids_mailing.forEach(element => {
            if (status) {
                let id_field = element.split('_')[1];
                if($('#business_' + id_field).val() != $(element).val()){
                    status = false;
                    console.log('false');
                    $('#address_same').prop('checked', status);
                    return false;
                } else {
                    console.log('true');
                    status = true;
                }
            }
        });
        $('#address_same').prop('checked', status);
    });

    $(document).on('change', '#title', function () {
        if($(this).val() == 'Others'){
            $('#title_other').prop('required', true)
        } else {
            $('#title_other').prop('required', false)
        }
    });

    $(document).on('change', '#nric_type', function () {
        if($(this).val() != ''){
            $('#nric_new').prop('disabled', false);
        } else {
            $('#nric_new').prop('disabled', true);
        }

        if($(this).val() == 'Others'){
            $('#nric_type_other').prop('required', true)
        } else {
            $('#nric_type_other').prop('required', false)
        }

        if($(this).val() == 'NRIC'){
            $('#nric_new').attr('maxlength', '12');
        } else {
            $('#nric_new').removeAttr('maxlength');
        }
    });

    $(document).on('change', '#nationality', function () {
        if($(this).val() == 'Non-Malaysian'){
            $('#nationality_other').prop('required', true)
        } else {
            $('#nationality_other').prop('required', false)
        }
    });

    $(document).on('change', '#race', function () {
        if($(this).val() == 'Others'){
            $('#race_other').prop('required', true)
        } else {
            $('#race_other').prop('required', false)
        }
    });

    $(document).on('change', '#spouse_nric_type', function () {
        if($(this).val() != ''){
            $('#spouse_nric_new').prop('disabled', false);
        } else {
            $('#spouse_nric_new').prop('disabled', true);
        }

        if($(this).val() == 'Others'){
            $('#spouse_nric_type_other').prop('required', true)
        } else {
            $('#spouse_nric_type_other').prop('required', false)
        }

        if($(this).val() == 'NRIC'){
            $('#spouse_nric_new').attr('maxlength', '12');
        } else {
            $('#spouse_nric_new').removeAttr('maxlength');
        }
    });

    $(document).on('change', '#emergency_nric_type', function () {
        if($(this).val() != ''){
            $('#emergency_nric_new').prop('disabled', false);
        } else {
            $('#emergency_nric_new').prop('disabled', true);
        }

        if($(this).val() == 'Others'){
            $('#emergency_nric_type_other').prop('required', true)
        } else {
            $('#emergency_nric_type_other').prop('required', false)
        }

        if($(this).val() == 'NRIC'){
            $('#emergency_nric_new').attr('maxlength', '12');
        } else {
            $('#emergency_nric_new').removeAttr('maxlength');
        }
    });

    $(document).on('change', '#marital_status', function () {
        if($(this).val() == 'Others'){
            $('#marital_status_other').prop('required', true)
        } else {
            $('#marital_status_other').prop('required', false)
        }
    });

    $(document).on('click', '.UpdateProfile', function () {
        $('#submitform').modal('hide');
        setTimeout(function(){ $('.submitform').click(); }, 500);
    });

    $(document).on('click', '.radio1', function () {
        if($(this).val() == 'Others'){
            $('#profiling_fund_source_other').prop('required', true)
        } else {
            $('#profiling_fund_source_other').prop('required', false)
        }
    });

    $(document).on('change', '#nric_new', function () {
        if($('#nric_type').val() == 'NRIC'){
            let currentYear = new Date().getFullYear().toString().substring(2);
            let val = $(this).val().match(/.{2}/g);
            let y = '';
            if(parseInt(val[0]) > parseInt(currentYear) && parseInt(val[0] < 99)){
                y = '19' + val[0];
            }
            else if(parseInt(val[0]) > parseInt('00') && parseInt(val[0]) <= parseInt(currentYear)){
                y = '20' + val[0];
            }
            else{
                y = '19' + val[0];
            }
            let date = moment(new Date(y + '-' + val[1] + '-'  + val[2])).format('YYYY-MM-DD');
            $('#birth_date').val(date);
        }
    });

    $(document).on('click', '#emergency_same', function () {
        if ($(this).is(":checked")) {
            $('#emergency_name').val($('#spouse_name').val());
            $('#emergency_contact').val($('#spouse_contact').val());
            $('#emergency_relationship').val($('#spouse_relationship').val());
            $('#emergency_nric_type').val($('#spouse_nric_type').val());
            $('#emergency_nric_type_other').val($('#spouse_nric_type_other').val());
            $('#emergency_nric_new').val($('#spouse_nric_new').val());
            $('#emergency_nric_old').val($('#spouse_nric_old').val());
            $('#emergency_occupation').val($('#spouse_occupation').val());
            $('#emergency_employer').val($('#spouse_employer').val());
        } else {
            $('#emergency_name').val('');
            $('#emergency_contact').val('');
            $('#emergency_relationship').val('');
            $('#emergency_nric_type').val('');
            $('#emergency_nric_type_other').val('');
            $('#emergency_nric_new').val('');
            $('#emergency_nric_old').val('');
            $('#emergency_occupation').val('');
            $('#emergency_employer').val('');
        }
    });

    $(document).on('change', '#employment_status', function () {
        if($(this).val() != 'Others'){
            $('#employment_status_other').prop('required', false);
            $('#employment_years').prop('required', true);
            $('#employment_name').prop('required', true);
            $('#employment_occupation').prop('required', true);
            $('#employment_type').prop('required', true);
            $('#employment_address').prop('required', true);
            $('#employment_postcode').prop('required', true);
            $('#employment_country').prop('required', true);
            $('#employment_state').prop('required', true);
            $('#employment_city').prop('required', true);

            $('.employment_status_other').html('Unemployed / Others');
            $('.employment_years').html('Employment Years <span class="text-danger">*</span>');
            $('.employment_name').html('Name of Employer / Company <span class="text-danger">*</span>');
            $('.employment_occupation').html('Occupation / Designation <span class="text-danger">*</span>');
            $('.employment_type').html('Type of Business of Employer / Company <span class="text-danger">*</span>');
            $('.employment_address').html('Address <span class="text-danger">*</span>');
            $('.employment_postcode').html('Postcode <span class="text-danger">*</span>');
            $('.employment_country').html('Country <span class="text-danger">*</span>');
            $('.employment_state').html('State <span class="text-danger">*</span>');
            $('.employment_city').html('City <span class="text-danger">*</span>');
        } else {
            $('#employment_status_other').prop('required', true);
            $('#employment_years').prop('required', false);
            $('#employment_name').prop('required', false);
            $('#employment_occupation').prop('required', false);
            $('#employment_type').prop('required', false);
            $('#employment_address').prop('required', false);
            $('#employment_postcode').prop('required', false);
            $('#employment_country').prop('required', false);
            $('#employment_state').prop('required', false);
            $('#employment_city').prop('required', false);

            $('.employment_status_other').html('Unemployed / Others <span class="text-danger">*</span>');
            $('.employment_years').html('Employment Years');
            $('.employment_name').html('Name of Employer / Company');
            $('.employment_occupation').html('Occupation / Designation');
            $('.employment_type').html('Type of Business of Employer / Company');
            $('.employment_address').html('Address');
            $('.employment_postcode').html('Postcode');
            $('.employment_country').html('Country');
            $('.employment_state').html('State');
            $('.employment_city').html('City');
        }
    });

});
