$(document).ready(function () {

    $('#StatementListTable').DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        bLengthChange: false,
        ajax: {
            "url": "/statements/ajax",
            "type": "POST",
            "data": function (d) {
                d._token = $('meta[name="csrf-token"]').attr('content');
                d.type = $('#tableType').val();
            },
        },
        columns: [{
                data: 'DT_RowIndex',
                name: 'DT_RowIndex',
                className: "text-center align-middle",
                orderable: false,
                searchable: false,
            },
            {
                data: 'info',
                name: 'info',
                render: function (data) {
                    let entities = [
                        ['amp', '&'],
                        ['apos', '\''],
                        ['#x27', '\''],
                        ['#x2F', '/'],
                        ['#39', '\''],
                        ['#47', '/'],
                        ['lt', '<'],
                        ['gt', '>'],
                        ['nbsp', ' '],
                        ['quot', '"']
                    ];
                    for (let i = 0, max = entities.length; i < max; ++i) {
                        data = data.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);
                    }

                    return data;
                },
            },
            {
                data: 'id',
                orderable: false,
                className: "text-center",
                render: function (data, type, row) {
                        return '<a href="/statements/' + data + '/show" class="btn btn-sm d-sm-inline-block ml-1 btn-primary">View Statements</a>';
                },
            },
        ],
        order: [
            [0, 'asc']
        ],
        pageLength: 10,
        language: {
            paginate: {
                previous: "<i class='fas fa-angle-left'></i>",
                next: "<i class='fas fa-angle-right'></i>"
            },
        },
    });
});
