$(document).ready(function () {

    var PaymentListTable = $('#PaymentListTable').DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        bLengthChange: false,
        ajax: {
            "url": "/payment/list/ajax",
            "type": "POST",
            "data": function (d) {
                d._token = $('meta[name="csrf-token"]').attr('content');
                d.status = $("#filter-payment-status").val();
                d.dt_start = $("#dt-start").val();
                d.dt_end = $("#dt-end").val();
            },
        },
        columns: [{
                data: 'DT_RowIndex',
                name: 'DT_RowIndex',
                className: "text-center align-middle",
                orderable: false,
                searchable: false,
            },
            {
                data: 'created_at',
                name: 'created_at',
                className: "text-center",
                render: function (data, type, row) {
                    return moment(data).format('DD/MM/YYYY');
                }
            },
            {
                data: 'ref_no',
                name: 'ref_no',
                className: "text-left",
                render: function (data, type, row) {
                    let html = 'Reference No: ' + data + '<br>' +
                                'Product : ' + row.product;
                    return html;
                },
            },
            {
                data: 'username',
                name: 'username',
                className: "text-left",
            },
            {
                data: 'code',
                name: 'code',
                className: "text-left",
            },
            {
                data: 'amount',
                name: 'amount',
                className: "text-right",
                render: function (data, type, row) {
                    return 'RM ' + data.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
            },
            {
                data: 'txn_status',
                name: 'txn_status',
                className: "text-center",
                render: function (data, type, row) {
                    let badge;
                    if(data === 0){
                        badge = '<span class="badge badge-pill badge-danger">Failed</span>';
                    }
                    else{
                        badge = '<span class="badge badge-pill badge-success">Success</span>';
                    }

                    return badge;
                }
            },
            // {
            //     data: 'application_id',
            //     orderable: false,
            //     className: "text-center",
            //     render: function (data, type, row) {
            //         return '<a href="/applications/' + data + '/show" class="btn btn-sm d-sm-inline-block ml-1 btn-primary">Evaluate</a>';
            //     },
            // },
        ],
        order: [
            [0, 'asc']
        ],
        pageLength: 10,
        language: {
            paginate: {
                previous: "<i class='fas fa-angle-left'></i>",
                next: "<i class='fas fa-angle-right'></i>"
            },
        },
    });

    $(document).on('change', '#filter-payment-status', function () {
        PaymentListTable.draw();
    });

    //$("#dt-start")
    $(document).on('change', '#dt-start', function () {
        PaymentListTable.draw();
    });
    $(document).on('change', '#dt-end', function () {
        PaymentListTable.draw();
    });

});
