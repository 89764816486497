$(document).ready(function () {

    $('#ProductsListTable').DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        ajax: {
            "url": "/settings/products/table/ajax",
            "type": "POST",
            "data": function (d) {
                d._token = $('meta[name="csrf-token"]').attr('content')
            },
        },
        columns: [
            {
                data: 'DT_RowIndex',
                name: 'DT_RowIndex',
                orderable: false,
                searchable: false,
            },
            {
                data: 'name',
                name: 'name',
                className: "text-left",
                render: function (data, type, row) {
                    let description = (row.description != null) ? '<br><small>'+row.description+'</small>' : '';
                    return data + description;
                }
            },
            {
                data: "product_id",
                orderable: false,
                className: "text-center",
                render: function (data) {
                    if (data != null) {
                        let view    = '<a href="/settings/products/'+data+'/edit" class="btn btn-sm d-sm-inline-block btn-primary m-1" title="Edit"><i class="fas fa-list"></i></a>';
                        return view;
                    } else {
                        return '';
                    }
                },
            },
        ],
        order: [
            [1, 'desc']
        ],
        pageLength: 25,
        language: {
            paginate: {
                previous: "<i class='fas fa-angle-left'></i>",
                next: "<i class='fas fa-angle-right'></i>"
            }
        }
    });

});
